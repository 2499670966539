export default theme => (
  {
    carouselItem: {
      display: 'inline-block',
      verticalAlign: 'top',
    },
    containerSize: {
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
      width: '90%',
    },
    itemPadding: {
      [theme.breakpoints.down('md')]: {
        marginBottom: '16px',
        padding: 0,
      },
    },
  }
);
