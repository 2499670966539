import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '../../../utils/withStyles';
import styles from './styles';

const useStyles = makeStyles(styles);

const FeaturedSectionCardLayout = ({ children, menuLayout }) => {
  const classes = useStyles({ menuLayout });
  return (
    <span className={classes.adaptCard}>
      {children}
    </span>
  );
};

FeaturedSectionCardLayout.propTypes = {
  menuLayout: PropTypes.string.isRequired,
};

export default memo(FeaturedSectionCardLayout);
