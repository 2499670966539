import { darkenToReadableColor } from '../../../utils/colors';

export default (theme) => {
  const { dishCard } = theme.consumer.menus;
  return {
    addToCartButton: {
      '&:first-child': {
        marginLeft: 0,
      },
      '&:hover': {
        borderColor: darkenToReadableColor('#FFFFFF', dishCard.description.color),
      },
      borderColor: darkenToReadableColor('#FFFFFF', dishCard.description.color),
      color: darkenToReadableColor('#FFFFFF', dishCard.description.color),
      fontFamily: dishCard.description.fontFamily && `"${dishCard.description.fontFamily}"`,
      marginBottom: theme.spacing(0.5),
      marginLeft: theme.spacing(2),
      position: 'relative',
      textTransform: 'none',
      zIndex: 3,
    },
    dishCard: {
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(0, 2, 4, 2),
      },
      borderBottom: '1px solid #CCC',
      borderRadius: 0,
      marginBottom: theme.spacing(4),
      padding: theme.spacing(2),
      position: 'relative',
    },
    dishCardBottom: {
      alignItems: 'center',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: dishCard.description.textAlign === 'center' ? 'center' : 'flex-start',
    },
    dishCardFeatured: {
      backgroundColor: dishCard.featured.backgroundColor,
      borderBottom: 'none',
      borderRadius: '8px',
    },
    dishCardInnerFeaturedWithPhoto: {
      [theme.breakpoints.down('md')]: {
        marginLeft: '0 !important',
        minHeight: '0 !important',
      },
    },
    dishCardInnerWithPhoto: props => ({
      [theme.breakpoints.down('md')]: {
        marginLeft: 0,
        minHeight: 0,
      },
      marginLeft: props.isMobileCardWidth ? 0 : theme.spacing(24),
      minHeight: theme.spacing(16),
    }),
    dishDescription: {
      [theme.breakpoints.down('md')]: {
        fontSize: '0.8em',
      },
      color: dishCard.description.color,
      display: 'flow-root',
      fontFamily: dishCard.description.fontFamily && `"${dishCard.description.fontFamily}"`,
      fontSize: '1em',
      marginBottom: theme.spacing(2),
      textAlign: dishCard.description.textAlign,
    },
    dishFeaturedTag: {
      color: dishCard.description.color,
      fontWeight: 'bold',
      marginBottom: theme.spacing(2),
      textTransform: 'uppercase',
    },
    dishLinkOverlay: {
      background: 'transparent !important',
      borderRadius: '4px',
      boxShadow: 'none !important',
      height: '100%',
      left: 0,
      position: 'absolute',
      top: 0,
      width: '100%',
      zIndex: 1,
    },
    dishName: {
      [theme.breakpoints.down('md')]: {
        fontSize: '1em',
      },
      color: dishCard.name.color && `${dishCard.name.color} !important`,
      fontFamily: dishCard.name.fontFamily && `"${dishCard.name.fontFamily}" !important`,
      fontSize: '1.2em',
      fontWeight: dishCard.name.fontWeight,
      marginBottom: theme.spacing(2),
      textAlign: dishCard.name.textAlign,
      textTransform: dishCard.name.textTransform,
    },
    dishPrice: {
      '&:after': {
        content: "' \u00b7 '",
      },
      '&:last-child:after': {
        content: 'none',
      },
      fontWeight: 'normal',
    },
    dishTag: {
      '&:first-child': {
        marginLeft: 0,
      },
      borderRadius: '50%',
      color: '#FFF',
      display: 'inline-block',
      fontFamily: '"Roboto Mono", monospace',
      fontSize: '0.8em',
      fontWeight: 'bold',
      height: theme.spacing(3),
      lineHeight: `${theme.spacing(3)}px`,
      marginLeft: theme.spacing(2),
      overflow: 'hidden',
      textAlign: 'center',
      width: theme.spacing(3),
      zIndex: 2,
    },
    dishTagImg: {
      borderRadius: '0 !important',
      objectFit: 'contain',
    },
    dishTagsContainer: {
      '&:first-child': {
        marginLeft: 0,
      },
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'flex-start',
      marginLeft: theme.spacing(2),
    },
    extraGroupName: {
      '&:after': {
        content: 'none !important',
      },
    },
    extrasContainer: {
      display: 'block',
    },
    extrasTag: {
      '&:after': {
        content: "' \u00b7 '",
      },
      '&:last-child': {
        marginRight: 0,
      },
      '&:last-child:after': {
        content: 'none',
      },
      display: 'inline-block',
      fontWeight: 'normal',
      marginRight: theme.spacing(0.5),
    },
    orderingButton: {
      '&:first-child': {
        marginLeft: 0,
      },
      '&:hover': {
        borderColor: darkenToReadableColor('#FFFFFF', theme.palette.primary.main),
      },
      borderColor: darkenToReadableColor('#FFFFFF', theme.palette.primary.main),
      color: darkenToReadableColor('#FFFFFF', theme.palette.primary.main),
      fontFamily: dishCard.description.fontFamily && `"${dishCard.description.fontFamily}"`,
      marginBottom: theme.spacing(0.5),
      marginLeft: theme.spacing(2),
      padding: theme.spacing(0.5),
      position: 'relative',
      textTransform: 'none',
      zIndex: 3,
    },
    photosButton: {
      '&:first-child': {
        marginLeft: 0,
      },
      color: dishCard.description.color,
      fontFamily: dishCard.description.fontFamily && `"${dishCard.description.fontFamily}"`,
      marginLeft: theme.spacing(2),
      padding: theme.spacing(0.5),
      position: 'relative',
      zIndex: 3,
    },
    popBubble: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
      float: 'right',
      marginBottom: theme.spacing(4),
      marginLeft: theme.spacing(2),
      position: 'relative',
      zIndex: 4,
    },
    popBubbleMobile: props => ({
      [theme.breakpoints.down('sm')]: {
        display: 'block',
      },
      display: 'none',
      float: 'right',
      marginBottom: props.menuItem.popsCount > 0 ? theme.spacing(2.5) : 0,
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(-4),
      position: 'relative',
      zIndex: 4,
    }),
    reviewsButton: {
      '&:first-child': {
        marginLeft: 0,
      },
      color: dishCard.description.color,
      fontFamily: dishCard.description.fontFamily && `"${dishCard.description.fontFamily}"`,
      marginLeft: theme.spacing(2),
      minWidth: 0,
      padding: theme.spacing(0.5),
      position: 'relative',
      zIndex: 3,
    },
  };
};
