import React from 'react';
import PropTypes from 'prop-types';

import { nl2br } from '../../utils/utils';
import { themeShape, withTheme } from '../../utils/withTheme';

import CustomContent from '../shared/CustomContent';
import MenuItemCardContainer from './MenuItemCardContainer';
import Grid from '../../shared/Grid';
import { AH, AHLevelProvider } from '../shared/AccessibleHeading';

class MenuSubsection extends React.PureComponent {
  renderDescription() {
    if (this.props.subsectionType !== 'custom' && this.props.description) {
      return <p className="subsection-description">{nl2br(this.props.description)}</p>;
    }
    return null;
  }

  renderMenuItemCard(menuItem) {
    return (
      <MenuItemCardContainer
        currency={this.props.currency}
        displayExtraGroups={this.props.displayExtraGroups}
        includeItemLink={this.props.includeItemLink}
        itemBackgroundColor={this.props.itemBackgroundColor}
        menuBackgroundColor={this.props.menuBackgroundColor}
        menuEnabled={this.props.menuEnabled}
        isMenuOrderingAvailable={this.props.isMenuOrderingAvailable}
        menuItem={menuItem}
        menuItemCart={this.props.menuItemCart}
        menuLayout={this.props.menuLayout}
        menuOrderingUrl={this.props.menuOrderingUrl}
        sectionBackgroundColor={this.props.sectionBackgroundColor}
        showAddToCartButton={this.props.showAddToCartButton}
        locationId={this.props.locationId}
      />
    );
  }

  renderContent(items) {
    if (this.props.subsectionType === 'custom') {
      const id = this.props.id ? `pm-custom-subsection-${this.props.id}` : null;

      let content = null;
      if (this.props.customHtml) {
        content = (
          <CustomContent id={`menu-subsection-${this.props.id}`} html={this.props.customHtml} />
        );
      } else if (this.props.customNode) {
        content = this.props.customNode;
      }

      return (
        <Grid container>
          <Grid item xs={12}>
            <div className="fr-view" id={id}>
              {content}
            </div>
          </Grid>
        </Grid>
      );
    } else if (this.props.subsectionType === 'default') {
      const menuFlow = this.props.theme.menuFlowSetting;
      let count = 0;

      if (this.props.theme.menuRowHeight === 'dynamic_row_height') {
        const groups = [];
        for (let i = 0; i < this.props.itemColumns; i += 1) {
          const group = [];
          for (let j = i; j < items.length; j += this.props.itemColumns) {
            group.push(items[menuFlow === 'column' ? count : j]);
            count += 1;
          }
          groups.push(group);
        }
        return (
          <Grid container>
            {groups.map((group, i) => (
              <Grid key={i} item sm={12 / this.props.itemColumns} xs={12}>
                {group.map(item => (
                  <React.Fragment key={item.id}>
                    {this.renderMenuItemCard(item)}
                  </React.Fragment>
                ))}
              </Grid>
            ))}
          </Grid>
        );
      } else if (this.props.theme.menuRowHeight === 'fixed_row_height') {
        const groups = [];
        for (let i = 0; i < Math.ceil(items.length / this.props.itemColumns); i += 1) {
          const group = [];
          for (let j = this.props.itemColumns * i; j < (this.props.itemColumns * (i + 1)) && j < items.length; j += 1) {
            group.push(items[j]);
          }
          groups.push(group);
        }
        return groups.map((group, i) => (
          <Grid key={i} container>
            {group.map(item => (
              <Grid key={item.id} item sm={12 / this.props.itemColumns} xs={12}>
                {this.renderMenuItemCard(item)}
              </Grid>
            ))}
          </Grid>
        ));
      }
    }
    return null;
  }

  render() {
    const items = this.props.items.filter(item => item.isEnabled && (item.isOrderingEnabled || !this.props.showAddToCartButton))
      .filter(menuItem => this.props.selectedDishTags.length === 0 || menuItem.dish.allSelectedDishTags
        .some(selectedTag => this.props.selectedDishTags.some(tag => tag.id.toString() === selectedTag.id || `${tag.id}-root` === selectedTag.id)));

    if (items.length === 0 && this.props.subsectionType !== 'custom') {
      return null;
    }

    return (
      <div className="pm-menu-subsection">

        {this.props.name && <AH variant="h4" className="subsection-title">{this.props.name}</AH>}
        <AHLevelProvider>
          {this.renderDescription()}
          {this.renderContent(items)}
        </AHLevelProvider>
      </div>
    );
  }
}

MenuSubsection.defaultProps = {
  customHtml: null,
  customNode: null,
  description: null,
  displayExtraGroups: false,
  itemBackgroundColor: null,
  items: [],
  menuBackgroundColor: null,
  menuItemCart: null,
  menuOrderingUrl: null,
  sectionBackgroundColor: null,
  showAddToCartButton: false,
};

MenuSubsection.propTypes = {
  columns: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  customHtml: PropTypes.string,
  customNode: PropTypes.node,
  description: PropTypes.string,
  displayExtraGroups: PropTypes.bool,
  id: PropTypes.number.isRequired,
  includeItemLink: PropTypes.bool.isRequired,
  isMenuOrderingAvailable: PropTypes.bool.isRequired,
  itemBackgroundColor: PropTypes.string,
  itemColumns: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(PropTypes.object),
  menuBackgroundColor: PropTypes.string,
  menuEnabled: PropTypes.bool.isRequired,
  menuItemCart: PropTypes.object,
  menuLayout: PropTypes.string.isRequired,
  menuOrderingUrl: PropTypes.string,
  sectionBackgroundColor: PropTypes.string,
  showAddToCartButton: PropTypes.bool,
  subsectionType: PropTypes.string.isRequired,
  theme: themeShape.isRequired,
};

export default withTheme(MenuSubsection);
