import React, { Fragment, useCallback, useState } from 'react';
import { Icon, Button } from '@popmenu/common-ui';
import { Clock } from '@popmenu/web-icons';

import type { MenuDeliveryOpeningRange } from '~/libs/gql/types';
import type { TranslateFunction } from '~/utils/i18n';
import { useIntl } from '~/utils/withIntl';
import { makeStyles } from '~/utils/withStyles';

import MenuHoursModal from '../MenuHoursModal/MenuHoursModal';

import { formatDaysRollup, formatTime } from '../../../utils/time';

import menuHoursBannerStyles from './styles';
import { AH } from '../../shared/AccessibleHeading';

const formatAvailableHoursText = (range: MenuDeliveryOpeningRange, t: TranslateFunction) => {
  let text = '';

  if (range.openAllDay && range.days.length === 7) {
    text = t('models.menu/menu_hours.menu_banner_text.available_all_day_every_day');
  } else if (range.openAllDay && range.days.length < 7) {
    text = t('models.menu/menu_hours.menu_banner_text.available_all_day_days', {
      days: formatDaysRollup(range.days, true),
    });
  } else if (!range.openAllDay && range.days.length === 7) {
    text = t('models.menu/menu_hours.menu_banner_text.available_every_day_from_until', {
      from: formatTime(range.openTime),
      until: formatTime(range.closeTime),
    });
  } else if (!range.openAllDay && range.days.length < 7) {
    text = t('models.menu/menu_hours.menu_banner_text.available_days_from_until', {
      days: formatDaysRollup(range.days, true),
      from: formatTime(range.openTime),
      until: formatTime(range.closeTime),
    });
  }

  return text;
};

const useStyles = makeStyles(menuHoursBannerStyles);

interface MenuHoursBannerProps {
  menuHours: MenuDeliveryOpeningRange[];
}

const MenuHoursBanner = ({ menuHours }: MenuHoursBannerProps) => {
  const classes = useStyles();
  const t = useIntl();
  const [showMenuHoursModal, setShowMenuHoursModal] = useState(false);
  const openMenuHoursModal = useCallback(() => setShowMenuHoursModal(true), [setShowMenuHoursModal]);
  const closeMenuHourModal = useCallback(() => setShowMenuHoursModal(false), [setShowMenuHoursModal]);

  return (
    <Fragment>
      <div role="banner" className={classes.menuHoursBannerContainer}>
        <Icon icon={Clock} className={classes.menuHoursBannerIcon} />
        <AH variant="h4" className={classes.menuHoursBannerHeader}>{t('models.menu/menu_hours.menu_banner_text.unavailable')}</AH>
        {menuHours.length === 1 && menuHours[0] && (
          <p className={classes.menuHoursBannerAvailableHoursText}>{formatAvailableHoursText(menuHours[0], t)}</p>
        )}
        {menuHours.length > 1 && (
          <Fragment>
            <Button
              className={classes.menuHoursBannerButton}
              color="primary"
              onClick={openMenuHoursModal}
              size="large"
              variant="text"
            >
              {t('models.menu/menu_hours.menu_banner_button_text')}
            </Button>
            {showMenuHoursModal && (
              <MenuHoursModal
                closeModal={closeMenuHourModal}
                menuHours={menuHours}
                show={showMenuHoursModal}
              />
            )}
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default MenuHoursBanner;
