import type { Theme } from '@material-ui/core/styles';
import type { StyleRules } from '@material-ui/styles';
import type { MenuItemFragment } from '~/libs/gql/fragments/menus/MenuItemFragment.generated';
import type { ClassNameMap } from '@material-ui/core/styles/withStyles';

const styles = (theme: Theme) => {
  const { dishCard } = theme.consumer.menus;
  return {
    dishCard: {
      alignItems: 'center',
      borderRadius: 0,
      display: 'flex',
      marginBottom: theme.spacing(2),
      padding: theme.spacing(1.5),
      position: 'relative',
    },
    dishCardContent: {
      paddingRight: theme.spacing(2),
      width: '100%',
    },
    dishCardExtras: {
      marginRight: theme.spacing(2),
      minWidth: theme.spacing(5),
    },
    dishCardExtrasWrapper: props => ({
      alignItems: 'flex-end',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: !(props.menuItem.extras.length > 0 || props.menuItem.sizes.length > 0) ? 'flex-end' : 'flex-start',
      marginLeft: dishCard.name.textAlign === 'center' ? undefined : 'auto',
      minWidth: 'auto',
    }),
    dishCardFeatured: {
      backgroundColor: dishCard.featured.backgroundColor,
      borderBottom: 'none',
      borderRadius: '8px',
    },
    dishCardInner: props => ({
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      marginLeft: !(props.menuItem.showFeaturedPhoto && props.menuItem.featuredPhoto && props.menuItem.featuredPhoto.thumbnailUrl) ? 0 : theme.spacing(2),
      marginTop: theme.spacing(1),
      width: '100%',
    }),
    dishFeaturedTag: {
      color: dishCard.description.color,
      fontWeight: 'bold',
      marginBottom: theme.spacing(2),
      textTransform: 'uppercase',
    },
    dishLinkOverlay: {
      background: 'transparent !important',
      borderRadius: '4px',
      boxShadow: 'none !important',
      height: '100%',
      left: 0,
      position: 'absolute',
      top: 0,
      width: '100%',
      zIndex: 2,
    },
    dishName: {
      [theme.breakpoints.down('md')]: {
        fontSize: '1em',
      },
      color: `${dishCard.name.color} !important`, // For embeds
      fontFamily: dishCard.name.fontFamily && `"${dishCard.name.fontFamily}"`,
      fontSize: '1.2em',
      fontWeight: dishCard.name.fontWeight,
      textAlign: dishCard.name.textAlign,
      textTransform: dishCard.name.textTransform,
    },
    dishPhotoWrap: {
      float: 'left',
      overflow: 'hidden',
    },
    dishPrice: {
      '&:after': {
        content: "' \u00b7 '",
      },
      '&:last-child:after': {
        content: 'none',
      },
    },
    dishPriceWrapper: props => ({
      color: dishCard.description.color,
      fontFamily: dishCard.description.fontFamily && `"${dishCard.description.fontFamily}"`,
      fontSize: '1em',
      marginRight: (props.menuItem.extras.length > 0 || props.menuItem.sizes.length > 0) ? 'auto' : theme.spacing(1),
      minWidth: theme.spacing(5),
    }),
    dishReviewBtn: {
      marginLeft: theme.spacing(1),
    },
    dishTag: {
      '&:first-child': {
        marginLeft: 0,
      },
      borderRadius: '50%',
      color: '#FFF',
      display: 'inline-block',
      fontFamily: '"Roboto Mono", monospace',
      fontSize: '0.8em',
      fontWeight: 'bold',
      height: theme.spacing(3),
      lineHeight: `${theme.spacing(3)}px`,
      marginLeft: theme.spacing(2),
      overflow: 'hidden',
      textAlign: 'center',
      width: theme.spacing(3),
    },
    dishTagImg: {
      borderRadius: '0 !important',
      objectFit: 'contain',
    },
    dishTagsContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      marginLeft: theme.spacing(1),
      zIndex: 3,
    },
    dishTitle: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: dishCard.name.textAlign === 'center' ? 'center' : 'flex-start',
      textAlign: dishCard.name.textAlign,
    },
    extrasContainer: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'left',
    },
    extrasTag: {
      '&:last-child': {
        marginRight: 0,
      },
      color: dishCard.description.color,
      display: 'inline-block',
      fontFamily: dishCard.description.fontFamily && `"${dishCard.description.fontFamily}"`,
      fontSize: '1em',
      marginRight: theme.spacing(0.5),
      textAlign: dishCard.description.textAlign,
    },
  } as const satisfies StyleRules<{ menuItem: MenuItemFragment }>;
};

export default styles;

export type ListMenuItemCardClassesProps = {
  classes: ClassNameMap<keyof ReturnType<typeof styles>>
};
