import React from 'react';
import PropTypes from 'prop-types';
import { compose, mapProps } from '@shakacode/recompose';
import { connect } from 'react-redux';

import { MenuLayout } from '~/utils/types';
import { withWindowSizeContext } from '../../shared/WindowSizeProvider';
import { getParam } from '../../utils/urls';
import { scrollTo } from '../../utils/dom';
import { openAddToCartModal, openMenuItemModal, openEditMenuItemCartModalFromCustomPage } from '../../shared/DishActions';
import { setMenuItemCartLocationId, setMenuItemCartIsScheduled } from '../../shared/MenuItemCartActions';
import { withRestaurant } from '../../utils/withRestaurant';
import { withTheme } from '../../utils/withTheme';

import ListMenuItemCard from './ListMenuItemCard';
import MenuItemCard from './MenuItemCard';
import NextMenuItemCard from './NextMenuItemCard';
import FeaturedMenuItemCard from './FeaturedMenuItemCard';

const matchOrderEventPath = (pathname) => {
  const template = '/orders/:slug/order';
  const templateParts = template.split('/'); // ['', 'orders', ':slug', 'order']
  const pathnameParts = pathname.split('/');
  const matchesFirstPart = templateParts[0] === pathnameParts[0];
  const matchesSecondPart = templateParts[1] === pathnameParts[1];
  const matchesThirdPart = pathnameParts[2]?.length > 0;
  const matchesFourthPart = templateParts[3] === pathnameParts[3];
  const matchesLength = templateParts.length === pathnameParts.length;
  const isMatch = matchesFirstPart && matchesSecondPart && matchesThirdPart && matchesFourthPart && matchesLength;
  return isMatch;
};

class MenuItemCardContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isMobileCardWidth: false,
    };
    this.cardRef = React.createRef();
    this.handleResize = this.handleResize.bind(this);
    this.openAddToCartModal = this.openAddToCartModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.preloadedDetails = false;
  }

  componentDidMount() {
    if (!this.preloadedDetails) {
      const slug = String(getParam('item'));
      if (slug === this.props.menuItem.slug) {
        this.preloadedDetails = true;
        setTimeout(() => {
          scrollTo(this.cardRef.current);
          if (getParam('openModal') !== 'false') {
            this.openModal();
          }
        }, 10);
      }
    }
    this.props.addResizeListener(this.handleResize);
    this.handleResize();
  }

  componentWillUnmount() {
    this.props.removeResizeListener(this.handleResize);
  }

  handleResize() {
    const isMobileCardWidth = !!(this.cardRef.current && this.cardRef.current.offsetWidth < 480);
    this.setState({ isMobileCardWidth });
  }

  openAddToCartModal() {
    console.log(`[POPMENU] openAddToCartModal(${this.props.menuItem.id})`);
    /**
     * openAddToCartModal will call the following reducer action to activate the
     * EditMenuItemCartModal and the AddToCartModal, so that when a NextMenuItemCard
     * order action is triggered and the online ordering page is navigated to, the
     * AddToCartModal isn't accessible until the need for the EditMenuItemCartModal
     * is determined and the order location information has been confirmed by the user.
     * This should ensure that the user is adding their selected item to the correct
     * location's cart.
     * This will also trigger the MenuItemCartLocationId so that the redux state will change
     * before the page is redirected to the OO page.
     *
     * In instances where the cart location either has only ASAP pickup or only Schedule for
     * Later available, as defined in the following if statements, the redux cart state shall
     * be updated to reflect the necessary alteration.
     */
    const cartLocation = this.props.menuItemCart?.location;
    const isModernLayout = this.props.modernLayout;
    const routerLocationHash = window.location.hash;
    const routerMatchPath = window.location.pathname;
    const routerLocationIsOnlineOrderingMenu = routerLocationHash?.includes('#menu') && routerLocationHash?.includes('location=');
    const routerLocationIsOrderingEventMenu = matchOrderEventPath(routerMatchPath);
    if (!cartLocation?.isOrderingAvailable && cartLocation?.isOrderingScheduleEnabled) {
      this.props.setMenuItemCartIsScheduled(true);
    }

    if (cartLocation?.isOrderingAvailable && !cartLocation?.isOrderingScheduleEnabled) {
      this.props.setMenuItemCartIsScheduled(false);
    }

    this.props.setMenuItemCartLocationId(this.props.locationId || this.props.menuItem.menu.locationId);
    if (isModernLayout || routerLocationIsOnlineOrderingMenu || routerLocationIsOrderingEventMenu) {
      this.props.openAddToCartModal(this.props.menuItem.id);
    } else {
      this.props.openEditMenuItemCartModalFromCustomPage(this.props.menuItem.id, this.props.menuItem.menu.id);
    }
  }

  openModal(e, showCartButton) {
    if (e) {
      e.preventDefault();
    }
    console.log(`[POPMENU] openMenuItemModal(${this.props.menuItem.id}, ${this.props.showAddToCartButton})`);
    this.props.openMenuItemModal(this.props.menuItem.id,
      this.props.showAddToCartButton || showCartButton,
      this.props.menuEnabled,
      this.props.menuItemCart?.scheduledAt,
      this.props.menuOrderingUrl);

    return false;
  }

  render() {
    const menuLayout = this.props.showAddToCartButton ? MenuLayout.NextLayout : this.props.menuLayout;

    switch (menuLayout) {
      case MenuLayout.ListLayout:
        return (
          <div ref={this.cardRef}>
            <ListMenuItemCard
              currency={this.props.currency}
              featuredSection={this.props.featuredSection}
              includeItemLink={this.props.includeItemLink}
              menuItem={this.props.menuItem}
              openAddToCartModal={this.openAddToCartModal}
              openModal={this.openModal}
              showAddToCartButton={this.props.showAddToCartButton}
            />
          </div>
        );
      case MenuLayout.NextLayout:

        if (this.props.featuredSection) {
          return (
            <FeaturedMenuItemCard
              currency={this.props.currency}
              displayExtraGroups={this.props.displayExtraGroups}
              includeItemLink={this.props.includeItemLink}
              isMenuOrderingAvailable={this.props.isMenuOrderingAvailable}
              isMobileCardWidth={this.props.featuredMobileCardWidth}
              menuEnabled={this.props.menuEnabled}
              menuItem={this.props.menuItem}
              menuItemCart={this.props.menuItemCart}
              menuOrderingUrl={this.props.menuOrderingUrl}
              openAddToCartModal={this.openAddToCartModal}
              openModal={this.openModal}
              showAddToCartButton={this.props.showAddToCartButton}
              showPhoto={!!(this.props.menuItem.showFeaturedPhoto && this.props.menuItem.featuredPhoto && this.props.menuItem.featuredPhoto.thumbnailUrl)}
            />
          );
        }
        return (
          <div ref={this.cardRef}>
            <NextMenuItemCard
              currency={this.props.currency}
              displayExtraGroups={this.props.displayExtraGroups}
              featuredSection={this.props.featuredSection}
              includeItemLink={this.props.includeItemLink}
              isMenuOrderingAvailable={this.props.isMenuOrderingAvailable}
              isMobileCardWidth={this.state.isMobileCardWidth}
              menuEnabled={this.props.menuEnabled}
              menuItem={this.props.menuItem}
              menuItemCart={this.props.menuItemCart}
              menuOrderingUrl={this.props.menuOrderingUrl}
              openAddToCartModal={this.openAddToCartModal}
              openModal={this.openModal}
              showAddToCartButton={this.props.showAddToCartButton}
            />
          </div>
        );
      default:
        return (
          <div ref={this.cardRef}>
            <MenuItemCard
              currency={this.props.currency}
              displayExtraGroups={this.props.displayExtraGroups}
              featuredSection={this.props.featuredSection}
              includeItemLink={this.props.includeItemLink}
              itemBackgroundColor={this.props.itemBackgroundColor}
              menuBackgroundColor={this.props.menuBackgroundColor}
              menuLayout={this.props.menuLayout}
              openAddToCartModal={this.openAddToCartModal}
              openModal={this.openModal}
              sectionBackgroundColor={this.props.sectionBackgroundColor}
              showAddToCartButton={this.props.showAddToCartButton}
              {...this.props.menuItem}
            />
          </div>
        );
    }
  }
}

MenuItemCardContainer.defaultProps = {
  displayExtraGroups: false,
  featuredSection: false,
  itemBackgroundColor: null,
  locationId: null,
  menuBackgroundColor: null,
  menuItemCart: null,
  menuOrderingUrl: null,
  sectionBackgroundColor: null,
  showAddToCartButton: false,
};

MenuItemCardContainer.propTypes = {
  currency: PropTypes.string.isRequired,
  displayExtraGroups: PropTypes.bool,
  featuredSection: PropTypes.bool,
  includeItemLink: PropTypes.bool.isRequired,
  isMenuOrderingAvailable: PropTypes.bool.isRequired,
  itemBackgroundColor: PropTypes.string,
  locationId: PropTypes.number,
  menuBackgroundColor: PropTypes.string,
  menuEnabled: PropTypes.bool.isRequired,
  menuItem: PropTypes.shape({
    id: PropTypes.number,
    slug: PropTypes.string,
  }).isRequired,
  menuItemCart: PropTypes.object,
  menuLayout: PropTypes.string.isRequired,
  menuOrderingUrl: PropTypes.string,
  openMenuItemModal: PropTypes.func.isRequired,
  sectionBackgroundColor: PropTypes.string,
  showAddToCartButton: PropTypes.bool,
};

export default compose(
  connect(() => ({}), { openAddToCartModal, openEditMenuItemCartModalFromCustomPage, openMenuItemModal, setMenuItemCartIsScheduled, setMenuItemCartLocationId }),
  withWindowSizeContext,
  withRestaurant,
  withTheme,
  mapProps(({ windowSize, restaurant, theme, ...props }) => ({
    ...props,
    addResizeListener: windowSize.addResizeListener,
    modernLayout: theme.dishLayout === 'modern_dish_layout',
    removeResizeListener: windowSize.removeResizeListener,
  })),
)(MenuItemCardContainer);
