import React from 'react';
import { compose } from '@shakacode/recompose';
import { Avatar, Button, Typography } from '@popmenu/common-ui';

import type { MenuItemFragment } from '~/libs/gql/fragments/menus/MenuItemFragment.generated';
import { formatCurrency } from '../../../utils/currency';
import { classNames, withStyles } from '../../../utils/withStyles';
import { withTheme, type WithThemeProps } from '../../../utils/withTheme';
import listMenuItemCardStyles, { type ListMenuItemCardClassesProps } from './styles';

import DishExtras from '../DishExtras';
import DishTags from '../DishTags';
import { AH } from '../../shared/AccessibleHeading';

interface ListMenuItemCardProps {
  currency: string;
  featuredSection?: boolean;
  includeItemLink: boolean;
  menuItem: MenuItemFragment;
  openModal: () => void;
}

type InnerListMenuItemCardProps = ListMenuItemCardProps & ListMenuItemCardClassesProps & WithThemeProps;

class ListMenuItemCard extends React.PureComponent<InnerListMenuItemCardProps> {
  static readonly defaultProps = {
    featuredSection: false,
  };

  renderLinkOverlay(accessibleId: string) {
    if (this.props.includeItemLink) {
      return (
        <Button
          data-menu-item-link="true"
          aria-labelledby={accessibleId}
          data-cy="menu_item_link"
          className={this.props.classes.dishLinkOverlay}
          href={this.props.menuItem.url ?? undefined}
          onClick={this.props.openModal}
          variant="contained"
        >
          {' '}
        </Button>
      );
    }
    return (
      <Button
        aria-labelledby={accessibleId}
        data-cy="menu_item_link"
        className={this.props.classes.dishLinkOverlay}
        onClick={this.props.openModal}
        variant="contained"
      >
        {' '}
      </Button>
    );
  }

  render() {
    const { classes, currency, featuredSection, menuItem, theme } = this.props;
    const showPhoto = !!(menuItem.showFeaturedPhoto && menuItem.featuredPhoto && menuItem.featuredPhoto.thumbnailUrl);
    const showExtras = menuItem.extras.length > 0 || menuItem.sizes.length > 0;
    const showPrice = !!(theme.showPrice && (menuItem.price || menuItem.priceCustomText));
    const accessibleId = `pm-menu-item-card-${menuItem.id}`;

    return (
      <section
        className={classNames(
          'pm-list-dish-card',
          classes.dishCard,
          menuItem.featured ? classes.dishCardFeatured : null,
          featuredSection && 'featured-item-dish-card',
        )}
      >
        {showPhoto && (
          <Avatar
            alt={menuItem.name}
            size="medium"
            src={menuItem.featuredPhoto?.thumbnailUrl ?? undefined}
            className={classes.dishPhotoWrap}
          />
        )}
        <div
          className={classNames(classes.dishCardInner, 'pm-next-dish-card-inner')}
          id={accessibleId}
        >
          <div className={classes.dishCardContent}>
            <span className={classes.dishTitle}>
              <AH typography className={classNames(classes.dishName, 'pm-list-dish-name')} variant="h4">
                {menuItem.name}
              </AH>
              {(theme.showMenuItemDishTags && menuItem.dish && menuItem.dish.allSelectedDishTags.length > 0) && (
                <DishTags
                  allSelectedDishTags={menuItem.dish.allSelectedDishTags}
                  classes={classes}
                />
              )}
            </span>
          </div>
          {(showPrice || showExtras) && (
            <div className={classes.dishCardExtrasWrapper}>
              <Typography className={classes.dishPriceWrapper}>
                {showPrice && (
                  <span className={classNames(classes.dishPrice, 'pm-list-dish-price')}>
                    {menuItem.priceCustomText ||
                      formatCurrency(menuItem.price, currency, { showSymbol: theme.showCurrencySymbol ?? false })}
                  </span>
                )}
              </Typography>
              {showExtras && (
                <Typography className={classes.dishCardExtras}>
                  <DishExtras
                    classes={classes}
                    component="span"
                    currency={currency}
                    extraGroups={[]}
                    extras={menuItem.extras}
                    sharedExtraGroups={[]}
                    showCurrencySymbol={theme.showCurrencySymbol ?? false}
                    showPrice={theme.showPrice ?? false}
                    sizes={menuItem.sizes}
                  />
                </Typography>
              )}
            </div>
          )}
        </div>
        {this.renderLinkOverlay(accessibleId)}
      </section>
    );
  }
}

export default compose<InnerListMenuItemCardProps, ListMenuItemCardClassesProps>(
  withStyles(listMenuItemCardStyles),
  withTheme,
)(ListMenuItemCard);
