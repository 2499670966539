import React from 'react';

import CustomImg from '../../shared/CustomImg';

interface CardPhotoProps {
  photoUrl: string;
  featuredTag?: React.ReactNode;
  name: string;
  reviewCount?: React.ReactNode;
  showFeaturedPhoto: boolean;
}

const CardPhoto = ({
  photoUrl,
  featuredTag = null,
  name,
  reviewCount = null,
  showFeaturedPhoto,
}: CardPhotoProps) => {
  if (!photoUrl || !showFeaturedPhoto) {
    return null;
  }
  return (
    <div className="card-photos dish-photos">
      <div className="photo-thumb" data-cy={'menu_item_card_photo'}>
        <CustomImg
          alt={name}
          className="photo-thumb-img"
          size="sm"
          src={photoUrl}
        />
      </div>
      {featuredTag}
      {reviewCount}
    </div>
  );
};

export default CardPhoto;
