export default theme => ({
  // Multi-column layout looks the same as mobile layout w/ photo above info
  dishPhoto: props => ({
    [theme.breakpoints.down('md')]: {
      borderRadius: props.menuItemMobileFullWidth ? '8px 8px 0 0' : '8px',
      margin: props.menuItemMobileFullWidth ? null : theme.spacing(2, 1, 0, 0),
    },
    borderRadius: props.isMobileCardWidth && props.menuItemMobileFullWidth ? '8px 8px 0 0' : '8px',
    height: '100%',
    left: 0,
    objectFit: 'cover',
    position: 'absolute !important',
    top: 0,
    width: '100%',
  }),
  dishPhotoWrap: props => ({
    [theme.breakpoints.down('md')]: {
      borderRadius: props.menuItemMobileFullWidth ? null : '8px',
      float: props.menuItemMobileFullWidth ? 'none' : 'left',
      height: props.menuItemMobileFullWidth ? 0 : theme.spacing(16),
      margin: props.menuItemMobileFullWidth ? theme.spacing(-2, -2, 2, -2) : `auto ${theme.spacing(2)}px auto auto`,
      paddingTop: props.menuItemMobileFullWidth ? '56.25%' : null,
      width: props.menuItemMobileFullWidth ? `calc(100% + ${theme.spacing(4)}px)` : theme.spacing(18),
    },
    float: props.isMobileCardWidth && props.menuItemMobileFullWidth ? 'none' : 'left',
    height: props.isMobileCardWidth && props.menuItemMobileFullWidth ? 0 : theme.spacing(16),
    margin: props.isMobileCardWidth ? theme.spacing(-2, 1, 2, -2) : 'auto',
    overflow: 'hidden',
    paddingTop: props.isMobileCardWidth && props.menuItemMobileFullWidth ? '56.25%' : 0,
    position: 'relative',
    width: props.isMobileCardWidth && props.menuItemMobileFullWidth ? `calc(100% + ${theme.spacing(4)}px)` : theme.spacing(20),
  }),
});
