import { MenuLayout } from '~/utils/types';

const layoutMinHeight = (layout) => {
  switch (layout) {
    case MenuLayout.NextLayout:
      return '30vh';
    case MenuLayout.ListLayout:
      return '8.5vh';
    case MenuLayout.Simple:
      return '56vh';
    case MenuLayout.SimpleTwo:
      return '51.5vh';
    case MenuLayout.Card:
      return '57.5vh';
  }

  return undefined;
};
export default theme => (
  {
    adaptCard: props => ({
      '& .featured-item-dish-card': {
        [theme.breakpoints.down('sm')]: {
          minHeight: '40vh',
          width: '100%',
        },
        backgroundColor: '#FFFFFF',
        minHeight: layoutMinHeight(props.menuLayout),
        width: props.menuLayout !== MenuLayout.NextLayout && 'calc(100% - 10px)',
      },
    }),
  }
);
