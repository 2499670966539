import { darkenToReadableColor } from '../../../utils/colors';

export default (theme) => {
  const { dishCard } = theme.consumer.menus;
  return {
    addToCartButton: {
      '&:first-child': {
        marginLeft: 0,
      },
      '&:hover': {
        borderColor: darkenToReadableColor('#FFFFFF', dishCard.description.color),
      },
      borderColor: darkenToReadableColor('#FFFFFF', dishCard.description.color),
      color: darkenToReadableColor('#FFFFFF', dishCard.description.color),
      fontFamily: dishCard.description.fontFamily && `"${dishCard.description.fontFamily}"`,
      marginBottom: theme.spacing(0.5),
      marginLeft: theme.spacing(2),
      position: 'relative',
      textTransform: 'none',
      zIndex: 3,
    },
    bottomContainer: {
      marginLeft: 0,
      marginTop: theme.spacing(1),
    },
    dishCard: {
      [theme.breakpoints.down('xs')]: {
        borderBottom: '1px solid #CCC',
        height: '100%',
        margin: 0,
        minWidth: '90%',
        padding: theme.spacing(2, 2, 4, 2),
      },
      backgroundColor: '#FFFFFF',
      margin: '0 0 16px 8px',
      minHeight: '28vh',
      padding: theme.spacing(2),
      position: 'relative',
      width: 'calc(100% - 16px);',
    },
    dishCardBottom: {
      [theme.breakpoints.down('sm')]: {
        paddingBottom: theme.spacing(3),
      },
      alignItems: 'center',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: dishCard.description.textAlign === 'center' ? 'center' : 'flex-start',
    },
    dishCardInnerFeaturedWithPhoto: {
      [theme.breakpoints.down('md')]: {
        marginLeft: '0 !important',
        minHeight: '0 !important',
      },
    },
    dishCardInnerWithPhoto: props => ({
      [theme.breakpoints.down('md')]: {
        marginLeft: 0,
        minHeight: 0,
      },
      marginLeft: props.isMobileCardWidth ? 0 : theme.spacing(24),
      minHeight: theme.spacing(16),
    }),
    dishDescription: {
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.8em',
        marginBottom: theme.spacing(10),
      },
      color: dishCard.description.color,
      display: 'flow-root',
      fontFamily: dishCard.description.fontFamily && `"${dishCard.description.fontFamily}"`,
      fontSize: '1em',
      textAlign: dishCard.description.textAlign,
    },
    dishFeaturedTag: {
      color: dishCard.description.color,
      fontWeight: 'bold',
      marginBottom: theme.spacing(2),
      textTransform: 'uppercase',
    },
    dishLinkOverlay: {
      background: 'transparent !important',
      borderRadius: '4px',
      boxShadow: 'none !important',
      height: '100%',
      left: 0,
      position: 'absolute',
      top: 0,
      width: '100%',
      zIndex: 1,
    },
    dishName: {
      [theme.breakpoints.down('md')]: {
        fontSize: '1em',
      },
      color: dishCard.name.color && `${dishCard.name.color} !important`,
      fontFamily: dishCard.name.fontFamily && `"${dishCard.name.fontFamily}" !important`,
      fontSize: '1.2em',
      fontWeight: dishCard.name.fontWeight,
      marginBottom: theme.spacing(2),
      textAlign: dishCard.name.textAlign,
      textTransform: dishCard.name.textTransform,
    },
    dishPhoto: {
      borderRadius: '8px',
      height: '100%',
      left: 0,
      objectFit: 'cover',
      position: 'absolute !important',
      top: 0,
      width: '100%',
    },
    dishPhotoWrap: {
      [theme.breakpoints.down('md')]: {
        borderRadius: '8px',
        float: 'none',
        height: '35vh',
        margin: `auto ${theme.spacing(2)}px auto auto`,
        paddingTop: 0,
        width: '100%',
      },
      float: 'left',
      height: theme.spacing(16),
      margin: 'auto',
      overflow: 'hidden',
      paddingTop: 0,
      position: 'relative',
      width: theme.spacing(20),
    },
    dishPrice: props => ({
      '&:last-child:after': {
        content: 'none',
      },
      display: 'block',
      fontWeight: 'normal',
      paddingBottom: props.isMobileCardWidth ? theme.spacing(6) : theme.spacing(4),
    }),
    dishTag: {
      '&:first-child': {
        marginLeft: 0,
      },
      borderRadius: '50%',
      color: '#FFF',
      display: 'inline-block',
      fontFamily: '"Roboto Mono", monospace',
      fontSize: '0.8em',
      fontWeight: 'bold',
      height: theme.spacing(3),
      lineHeight: `${theme.spacing(3)}px`,
      marginLeft: theme.spacing(2),
      overflow: 'hidden',
      textAlign: 'center',
      width: theme.spacing(3),
      zIndex: 2,
    },
    dishTagImg: {
      borderRadius: '0 !important',
      objectFit: 'contain',
    },
    dishTagsContainer: {
      '&:first-child': {
        marginLeft: 0,
      },
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'flex-start',
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(0.5),
    },
    extraGroupName: {
      '&:after': {
        content: 'none !important',
      },
    },
    extrasContainer: {
      display: 'block',
    },
    extrasTag: {
      '&:after': {
        content: "' \u00b7 '",
      },
      '&:last-child': {
        marginRight: 0,
      },
      '&:last-child:after': {
        content: 'none',
      },
      display: 'inline-block',
      fontWeight: 'normal',
      marginRight: theme.spacing(0.5),
    },
    orderingButton: {
      '&:first-child': {
        marginLeft: 0,
      },
      '&:hover': {
        borderColor: darkenToReadableColor('#FFFFFF', theme.palette.primary.main),
      },
      borderColor: darkenToReadableColor('#FFFFFF', theme.palette.primary.main),
      color: darkenToReadableColor('#FFFFFF', theme.palette.primary.main),
      fontFamily: dishCard.description.fontFamily && `"${dishCard.description.fontFamily}"`,
      marginBottom: theme.spacing(0.5),
      marginLeft: theme.spacing(2),
      padding: theme.spacing(0.5),
      position: 'relative',
      textTransform: 'none',
      zIndex: 3,
    },
    photosButton: {
      [theme.breakpoints.down('xs')]: {
        width: '80px',
      },
      '&:first-child': {
        marginLeft: 0,
      },
      color: dishCard.description.color,
      fontFamily: dishCard.description.fontFamily && `"${dishCard.description.fontFamily}"`,
      marginLeft: theme.spacing(2),
      padding: theme.spacing(0.5),
      position: 'relative',
      zIndex: 3,
    },
    popBubble: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
      float: 'right',
      marginBottom: theme.spacing(4),
      marginLeft: theme.spacing(2),
      position: 'relative',
      zIndex: 4,
    },
    popBubbleMobile: {
      [theme.breakpoints.down('sm')]: {
        display: 'block',
      },
      bottom: theme.spacing(4),
      display: 'none',
      float: 'right',
      left: '80%',
      position: 'absolute',
      zIndex: 4,
    },
    reviewsButton: {
      '&:first-child': {
        marginLeft: 0,
      },
      color: dishCard.description.color,
      fontFamily: dishCard.description.fontFamily && `"${dishCard.description.fontFamily}"`,
      marginLeft: theme.spacing(2),
      minWidth: 0,
      padding: theme.spacing(0.5),
      position: 'relative',
      zIndex: 3,
    },
  };
};
