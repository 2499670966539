import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose, mapProps } from '@shakacode/recompose';

import { nl2br } from '../../utils/utils';
import { classNames } from '../../utils/withStyles';
import { themeShape, withTheme } from '../../utils/withTheme';
import { openVipV2Modal } from '../../shared/ModalActions';
import { withCurrentSession } from '../../shared/CurrentSessionProvider';
import { withWindowSizeContext } from '../../shared/WindowSizeProvider';
import { withRestaurant } from '../../utils/withRestaurant';

import MenuSubsection from './MenuSubsection';
import Grid from '../../shared/Grid';
import { AH, AHLevelProvider } from '../shared/AccessibleHeading';

class MenuSection extends React.PureComponent {
  renderHeader() {
    if (this.props.name && this.props.isHeaderEnabled) {
      const style = {};
      if (this.props.theme.menuHeaderFont) {
        if (this.props.theme.menuHeaderFont.color) {
          style.color = this.props.theme.menuHeaderFont.color;
          style.borderBottomColor = this.props.theme.menuHeaderFont.color;
        }
        if (this.props.theme.menuHeaderFont.family) {
          style.fontFamily = `"${this.props.theme.menuHeaderFont.family}"`;
        }
      }
      return (
        <AH variant="h3" style={style} className="pm-menu-section-header">{this.props.name}</AH>
      );
    }
    return null;
  }

  renderDescription() {
    if (this.props.description) {
      const style = {};
      if (this.props.theme.menuHeaderFont) {
        if (this.props.theme.menuHeaderFont.color) {
          style.color = this.props.theme.menuHeaderFont.color;
        }
      }
      return (
        <p className="section-description" style={style}>
          {nl2br(this.props.description)}
        </p>
      );
    }
    return null;
  }

  /* eslint-disable jsx-a11y/click-events-have-key-events */
  /* eslint-disable jsx-a11y/no-static-element-interactions */
  renderSecretMessage() {
    if (this.props.isSecret) {
      return (
        <div onClick={() => this.props.openVipV2Modal()}>
          <div className="pm-secret-bg" />
          <span className="pm-secret-message">
            <button className="a" type="button">
              {this.props.signUpHeadingCta}<br />
              <FormattedMessage
                id="secret_message.view_secret_menu"
                defaultMessage="to view our secret menu!"
              />
            </button>
          </span>
        </div>
      );
    }
    return null;
  }
  /* eslint-enable jsx-a11y/click-events-have-key-events */
  /* eslint-enable jsx-a11y/no-static-element-interactions */

  render() {
    const subsections = this.props.subsections.filter(subsection => subsection.isEnabled);
    let columns = 1;
    let itemColumns = this.props.menuColumnCount || this.props.masterMenuColumnCount || this.props.theme.menuColumnCount;
    if (this.props.isFullWidth || this.props.isMobile) {
      columns = 1;
      itemColumns = 1;
    } else if (subsections.length > 1) {
      columns = subsections.length % 2 === 0 ? 2 : 3;
      itemColumns = 1;
    }

    const shouldRenderHeader = this.props.name && this.props.isHeaderEnabled;
    return (
      <div className={`pm-menu-wrap pm-theme-${this.props.menuLayout}`}>
        <div
          className={classNames(
            'pm-menu-section',
            this.props.isSecret ? 'pm-secret' : null,
            this.props.description ? 'has-description' : null,
          )}
          id={this.props.elementId}
          style={{
            backgroundColor: this.props.backgroundColor,
          }}
          tabIndex="-1"
        >
          <div
            aria-hidden={this.props.isSecret ? 'true' : 'false'}
            className={this.props.isSecret ? 'pm-secret-content' : null}
          >
            {shouldRenderHeader && this.renderHeader()}
            <AHLevelProvider>
              {this.renderDescription()}
              <Grid container spacing={0}>
                {subsections.map(subsection => (
                  <Grid key={subsection.id} item sm={12 / columns} xs={12}>
                    <MenuSubsection
                      columns={columns}
                      currency={this.props.currency}
                      displayExtraGroups={this.props.displayExtraGroups}
                      includeItemLink={this.props.includeItemLink}
                      isMenuOrderingAvailable={this.props.isMenuOrderingAvailable}
                      itemBackgroundColor={this.props.itemBackgroundColor}
                      itemColumns={itemColumns}
                      menuBackgroundColor={this.props.menuBackgroundColor}
                      menuEnabled={this.props.menuEnabled}
                      menuItemCart={this.props.menuItemCart}
                      menuLayout={this.props.menuLayout}
                      menuOrderingUrl={this.props.menuOrderingUrl}
                      sectionBackgroundColor={this.props.backgroundColor}
                      selectedDishTags={this.props.selectedDishTags}
                      showAddToCartButton={this.props.showAddToCartButton}
                      locationId={this.props.locationId}
                      {...subsection}
                    />
                  </Grid>
                ))}
              </Grid>
            </AHLevelProvider>
          </div>
          {this.renderSecretMessage()}
        </div>
      </div>
    );
  }
}

MenuSection.defaultProps = {
  backgroundColor: null,
  description: null,
  displayExtraGroups: false,
  elementId: null,
  isFullWidth: false,
  isHeaderEnabled: true,
  isOrderingEnabled: false,
  isSecret: false,
  itemBackgroundColor: null,
  menuBackgroundColor: null,
  menuColumnCount: null,
  menuItemCart: null,
  menuOrderingUrl: null,
  name: null,
  showAddToCartButton: false,
};

MenuSection.propTypes = {
  backgroundColor: PropTypes.string,
  currency: PropTypes.string.isRequired,
  description: PropTypes.string,
  displayExtraGroups: PropTypes.bool,
  elementId: PropTypes.string,
  includeItemLink: PropTypes.bool.isRequired,
  isFullWidth: PropTypes.bool,
  isHeaderEnabled: PropTypes.bool,
  isMenuOrderingAvailable: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isOrderingEnabled: PropTypes.bool,
  isSecret: PropTypes.bool,
  itemBackgroundColor: PropTypes.string,
  menuBackgroundColor: PropTypes.string,
  menuColumnCount: PropTypes.number,
  menuEnabled: PropTypes.bool.isRequired,
  menuItemCart: PropTypes.object,
  menuLayout: PropTypes.string.isRequired,
  menuOrderingUrl: PropTypes.string,
  name: PropTypes.string,
  openVipV2Modal: PropTypes.func.isRequired,
  showAddToCartButton: PropTypes.bool,
  signUpHeadingCta: PropTypes.string.isRequired,
  subsections: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    isEnabled: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      price: PropTypes.number,
    })),
  })).isRequired,
  theme: themeShape.isRequired,
};

export default compose(
  withCurrentSession,
  withTheme,
  withRestaurant,
  mapProps(({ restaurant, currentSession, ...props }) => ({
    ...props,
    isOrderingEnabled: restaurant.featureSetting.isOrderingEnabled,
    isSecret: props.isSecret && !currentSession.user,
    signUpHeadingCta: restaurant.signUpHeadingCta,
  })),
  connect(() => ({}), { openVipV2Modal }),
  withWindowSizeContext,
  mapProps(({ windowSize, ...props }) => ({
    ...props,
    isMobile: windowSize.isMobile,
  })),
)(MenuSection);
