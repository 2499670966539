import type { StyleRules, Theme } from '@material-ui/core/styles';

export default (theme: Theme) => {
  const bannerMargin = theme.spacing(2);
  return {
    menuHoursBannerAvailableHoursText: {
      fontSize: '1.15rem',
      marginBottom: bannerMargin,
      marginLeft: bannerMargin,
      marginRight: bannerMargin,
      marginTop: theme.spacing(1),
      textAlign: 'center',
    },
    menuHoursBannerButton: {
      fontSize: '1.15rem',
      marginBottom: theme.spacing(0),
      textTransform: 'capitalize',
    },
    menuHoursBannerContainer: {
      alignItems: 'center',
      backgroundColor: theme.palette.surface.light,
      border: `2px solid ${theme.palette.primary.main}`,
      borderRadius: '0.4rem',
      color: theme.palette.common.black,
      display: 'flex',
      flexDirection: 'column',
      marginBottom: bannerMargin,
      marginLeft: bannerMargin,
      marginRight: bannerMargin,
    },
    menuHoursBannerHeader: {
      fontSize: '1.25rem',
      fontWeight: 'bold',
      marginBottom: theme.spacing(0),
      marginTop: bannerMargin,
    },
    menuHoursBannerIcon: {
      color: theme.palette.primary.main,
      fontSize: '2.5rem',
      marginTop: bannerMargin,
    },
  } as const satisfies StyleRules;
};
