import React from 'react';
import { FormattedMessage } from 'react-intl';

import type { DishTagObject } from '~/libs/gql/types';
import Grid from '../../shared/Grid';
import { sortByKey } from '../../utils/arrays';
import { readableFontColor } from '../../utils/colors';
import { useTheme } from '../../utils/withTheme';

interface DishTagsBarProps {
  selectedState: DishTagObject[];
  handleDishTags: (selectedDishTags: DishTagObject[]) => void;
  dishTags: DishTagObject[];
}

const DishTagsBar = ({
  selectedState,
  handleDishTags,
  dishTags,
}: DishTagsBarProps) => {
  const theme = useTheme();

  const handleSelect = (tag: DishTagObject) => {
    const selectedDishTags = [...(selectedState || [])];
    const selectedIndex = selectedDishTags.findIndex(t => t.id === tag.id);
    if (selectedIndex > -1) {
      selectedDishTags.splice(selectedIndex, 1);
    } else {
      selectedDishTags.push(tag);
    }
    handleDishTags(selectedDishTags);
  };

  if (!theme.showMenuItemDishTags) {
    return null;
  }

  if (dishTags.length === 0) {
    return null;
  }
  return (
    <Grid container>
      <Grid item xs={12}>
        <div className="pm-dish-tags-key">
          <Grid container>
            <Grid
              item
              xs={6}
              sm={6}
              md={4}
              lg={3}
              className={selectedState.length === 0 ? 'pm-all-items' : 'pm-all-items unselected'}
              onClick={() => handleDishTags([])}
            >
              <span><FormattedMessage id="menus.dish_tag_bar.show_all_items" defaultMessage="Show All Items" /></span>
            </Grid>
            {sortByKey(dishTags, 'name').map(tag => (
              <Grid
                key={tag.id}
                item
                xs={6}
                sm={6}
                md={4}
                lg={3}
                className={selectedState.includes(tag) ? 'pm-dish-tag-key' : 'pm-dish-tag-key selected'}
                onClick={() => handleSelect(tag)}
              >
                {tag.imageIconUrl ? (
                  <img
                    alt={tag.name}
                    className="pm-dish-tag-image"
                    src={tag.imageIconUrl}
                    title={tag.name}
                  />
                ) :
                  (
                    <span
                      className="pm-dish-tag-abbr"
                      style={{ background: tag.color, color: readableFontColor(tag.color) }}
                    >
                      {tag.abbr}
                    </span>
                  )}
                <span className="pm-dish-tag-name">{tag.name}</span>
              </Grid>
            ))}
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};

export default DishTagsBar;
