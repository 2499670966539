import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { compose } from '@shakacode/recompose';

import { useDispatch, useSelector } from 'react-redux';
import { MenuLayout } from '~/utils/types';
import { withIntl } from '../../../../utils/withIntl';
import { themeShape, withTheme } from '../../../../utils/withTheme';

import Grid from '../../../../shared/Grid';
import MenuSectionSkeleton from '../../MenuSectionSkeleton';
import MenuSectionContentWrapper from '../../MenuSectionContentWrapper';
import MenuSubsection from '../../MenuSubsection';
import menuSectionQuery from '../../../../libs/gql/queries/menus/menuSectionQuery.gql';
import { updateMenuItems } from '../../GetItemContext';
import { INTERSECTION_OBSERVER_SUPPORTED } from '../../../shared/NavItemsV2/IntersectionObserverWrapper';
import { setFocusFirstSectionItemEffect } from '../../../../shared/MenuItemCartActions';
import { useScrollObserver } from '../../../../utils/useScrollObserver';

export const getFirstSectionItemNode = (sectionSlug) => {
  if (!sectionSlug) return null;

  const sectionNode = document?.querySelector(`#section-${sectionSlug}`);
  const firstItemNode = sectionNode?.querySelector('[data-menu-item-link=true]');
  return firstItemNode;
};

let onFirstSectionVisible = () => undefined;
const firstSectionVisiblePromise = new Promise((resolve) => {
  onFirstSectionVisible = resolve;
});

export const fetchMenuSection = (section, orderingEventId = -1) => firstSectionVisiblePromise.then(() => window.POPMENU_CLIENT.query({
  query: menuSectionQuery,
  variables: {
    orderingEventAvailable: !!orderingEventId && orderingEventId !== -1,
    orderingEventId: orderingEventId || -1,
    sectionId: section.id,
  },
}).then((response) => {
  const { data } = response;
  if (data?.menuSection) {
    const items = data.menuSection.subsections.map(s => s.items).flat();
    updateMenuItems(items);
  }
  return response.data;
}));

export const useFocusChangeEffect = ({ sectionSlug }) => {
  const dispatch = useDispatch();
  const selectedSectionSlug = useSelector(state => state.menuItemCart.selectedSectionSlug);
  const focusFirstSectionItemEffect = useSelector(state => state.menuItemCart.focusFirstSectionItemEffect);

  useEffect(() => {
    const slugMatch = selectedSectionSlug === sectionSlug;
    if (slugMatch && focusFirstSectionItemEffect === 'active') {
      const firstItemNode = getFirstSectionItemNode(selectedSectionSlug);
      if (firstItemNode) {
        firstItemNode.focus();
        dispatch(setFocusFirstSectionItemEffect('standby'));
      }
    }
  }, [selectedSectionSlug, focusFirstSectionItemEffect, dispatch, sectionSlug]);
};

const VirtualizedMenuSection = (props) => {
  const {
    backgroundColor,
    currency,
    data,
    loading,
    displayExtraGroups,
    index,
    includeItemLink,
    isMenuOrderingAvailable,
    itemBackgroundColor,
    menuBackgroundColor,
    menuEnabled,
    menuItemCart,
    menuOrderingUrl,
    setSelectedTab,
    selectedDishTags,
    slug,
    showAddToCartButton,
    theme,
    renderMobileMenuNavV2,
  } = props;
  const menuLayout = props.menuLayout || theme.defaultMenuLayout || MenuLayout.NextLayout;
  const menuSectionRef = useRef(null);
  const { scrollObserverRef, scrolledTo } = useScrollObserver({ threshold: 0.1 });
  if (scrolledTo) {
    onFirstSectionVisible();
  }

  useEffect(() => {
    const node = menuSectionRef?.current;
    if (!INTERSECTION_OBSERVER_SUPPORTED || !node || !renderMobileMenuNavV2 || !setSelectedTab) return () => null;

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setSelectedTab(index);
      }
    }, { rootMargin: '-40%' });
    observer.observe(node);
    return () => observer.disconnect();
  }, [renderMobileMenuNavV2, index, setSelectedTab]);

  useFocusChangeEffect({ sectionSlug: slug });

  let columns = 1;
  let itemColumns = props.menuColumnCount || props.masterMenuColumnCount || props.theme.menuColumnCount;

  if (loading || !data || !data.menuSection) {
    return (
      <MenuSectionContentWrapper
        elementId={`section-${slug}`}
        menuLayout={menuLayout}
        menuSectionRef={menuSectionRef}
        {...props}
      >
        <div ref={scrollObserverRef}>
          <MenuSectionSkeleton
            menuColumnCount={showAddToCartButton ? 1 : itemColumns}
          />
        </div>
      </MenuSectionContentWrapper>
    );
  }

  const { subsections } = data.menuSection;
  if (data.menuSection) {
    if (props.isFullWidth || props.isMobile) {
      columns = 1;
      itemColumns = 1;
    } else if (subsections.length > 1 && theme.subsectionDisplay === 'vertical') {
      columns = subsections.length % 2 === 0 ? 2 : 3;
      itemColumns = 1;
    }
  }

  return (
    <MenuSectionContentWrapper
      elementId={`section-${slug}`}
      menuLayout={menuLayout}
      menuSectionRef={menuSectionRef}
      scrollObserverRef={scrollObserverRef}
      {...props}
    >
      <Grid container spacing={0}>
        {subsections.map(subsection => (
          <Grid key={subsection.id} item sm={12 / columns} xs={12}>
            <MenuSubsection
              columns={columns}
              currency={currency}
              displayExtraGroups={displayExtraGroups}
              includeItemLink={includeItemLink}
              isMenuOrderingAvailable={isMenuOrderingAvailable}
              itemBackgroundColor={itemBackgroundColor}
              itemColumns={itemColumns}
              menuBackgroundColor={menuBackgroundColor}
              menuEnabled={menuEnabled}
              menuItemCart={menuItemCart}
              menuLayout={menuLayout}
              menuOrderingUrl={menuOrderingUrl}
              sectionBackgroundColor={backgroundColor}
              selectedDishTags={selectedDishTags}
              showAddToCartButton={showAddToCartButton}
              {...subsection}
            />
          </Grid>
        ))}
      </Grid>
    </MenuSectionContentWrapper>
  );
};

VirtualizedMenuSection.defaultProps = {
  allowReservation: true,
  backgroundColor: null,
  backgroundEffect: null,
  backgroundImageUrl: null,
  data: null,
  disclaimer: null,
  displayExtraGroups: false,
  displayPdfType: null,
  includeItemLink: true,
  isMenuOrderingAvailable: false,
  itemBackgroundColor: null,
  loading: false,
  menuItemCart: null,
  menuLayout: null,
  menuOrderingUrl: null,
  showAddToCartButton: false,
};

VirtualizedMenuSection.propTypes = {
  allowReservation: PropTypes.bool,
  backgroundColor: PropTypes.string,
  backgroundEffect: PropTypes.string,
  backgroundImageUrl: PropTypes.string,
  currency: PropTypes.string.isRequired,
  data: PropTypes.object,
  disclaimer: PropTypes.string,
  displayExtraGroups: PropTypes.bool,
  displayPdfType: PropTypes.string,
  includeItemLink: PropTypes.bool,
  isMenuOrderingAvailable: PropTypes.bool,
  itemBackgroundColor: PropTypes.string,
  loading: PropTypes.bool,
  menuItemCart: PropTypes.object,
  menuLayout: PropTypes.string,
  menuOrderingUrl: PropTypes.string,
  showAddToCartButton: PropTypes.bool,
  t: PropTypes.func.isRequired,
  theme: themeShape.isRequired,
};

export default compose(
  withIntl,
  withTheme,
)(VirtualizedMenuSection);
