import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { compose, mapProps } from '@shakacode/recompose';
import { Link } from 'react-router-dom';
import { addMinutes, isBefore, parseISO } from 'date-fns';
import { Box, Button, Icon, Typography } from '@popmenu/common-ui';
import { useSelector, useDispatch } from 'react-redux';

import { Image, MessageSquare, CartPlus } from '@popmenu/web-icons';
import { createEvent } from '~/utils/eventable';
import { nl2br } from '../../../utils/react';
import { formatCurrency } from '../../../utils/currency';
import { withPopmenuConfig } from '../../../utils/withPopmenuConfig';
import { withRestaurant } from '../../../utils/withRestaurant';
import { classNames, makeStyles } from '../../../utils/withStyles';
import { themeShape, withTheme } from '../../../utils/withTheme';
import { AH } from '../../shared/AccessibleHeading';
import nextMenuItemCardStyles from './styles';
import { setSelectedMenuItem } from '../../../shared/MenuItemCartActions';

import DishExtras from '../DishExtras';
import DishTags from '../DishTags';
import NextMenuItemCardPhoto from '../NextMenuItemCardPhoto';
import PopBubble from '../../pops/PopBubble';
import ModernEngagementIcons from '../../shared/ModernEngagementIcons';
import { isOutOfStock, itemIsOrderLinkable, itemIsOrderable, isSoldOut } from '../MenuHelpers';

const useStyles = makeStyles(nextMenuItemCardStyles);

const NextMenuItemCard = (props) => {
  const {
    currency,
    displayExtraGroups,
    includeItemLink,
    isMenuOrderingAvailable,
    isMobileCardWidth,
    menuEmbed,
    menuEnabled,
    menuItem,
    menuItemCart,
    menuOrderingUrl,
    openAddToCartModal,
    openModal,
    restaurant,
    showAddToCartButton,
    theme,
  } = props;
  const dispatch = useDispatch();
  const isScheduled = useSelector(state => state.menuItemCart.menuItemCartIsScheduled);
  const orderingEventId = useSelector(state => state.consumer.orderingEventId);
  const scheduledAt = useSelector(state => state.menuItemCart.menuItemCartScheduledAt);
  const classes = useStyles(props);

  const invalidCateringLeadTime = Boolean(
    menuItem.menu?.isCateringMenu &&
    menuItem.menu.cateringLeadTimeMinutes > 0 &&
    isScheduled &&
    scheduledAt &&
    isBefore(parseISO(scheduledAt), addMinutes(new Date(), menuItem.menu.cateringLeadTimeMinutes)),
  );
  const outOfStock = isOutOfStock({
    outOfStock: menuItem?.outOfStock,
    outOfStockUntil: menuItem?.outOfStockUntil,
    scheduledAt: menuItemCart?.scheduledAt,
  });
  const soldOut = isSoldOut(menuItem.orderingEventMaxAvailable);
  const showPhoto = !!(menuItem.showFeaturedPhoto && menuItem.featuredPhoto && menuItem.featuredPhoto.thumbnailUrl);
  const showDescription = !!(theme.showMenuItemDescription && menuItem.description);
  const showExtras = menuItem.extras.length > 0 || menuItem.sizes.length > 0 || (displayExtraGroups && menuItem.extraGroups.length > 0) || (displayExtraGroups && menuItem.sharedExtraGroups.length > 0);
  const showPrice = !!(theme.showPrice && (menuItem.price || menuItem.priceCustomText));
  const showDishTags = !!(theme.showMenuItemDishTags && menuItem.dish.allSelectedDishTags.length > 0);
  const showMenuItemPhotos = menuItem.featuredPhoto || menuItem.photosCount > 0;
  const ariaLabelId = `pm-menu-item-card-${menuItem.id}-label`;
  // Prevent linking to empty/non-existent description
  const ariaDescriptionId = (showDescription || showPrice || showExtras) ? `pm-menu-item-card-${menuItem.id}-description` : null;
  const containerId = `pm-menu-item-card-container-${menuItem.id}`;
  const isDineInCartType = menuItemCart ? menuItemCart.cartType === 'dine_in_cart_type' : false;
  const showOrderLink = (
    !menuEmbed &&
    theme.isOrderingButtonEnabled &&
    menuOrderingUrl
  );
  const modernLayout = theme.dishLayout === 'modern_dish_layout';
  const doordashDeliveryProhibited = menuItemCart?.fulfillmentType === 'delivery_fulfillment_type' && menuItem.containsAlcohol && menuItem.isDoordashDelivery;
  const orderableParams = { doordashDeliveryProhibited, invalidCateringLeadTime, isMenuOrderingAvailable, menuEnabled, menuItemIsEnabled: menuItem.isOrderingEnabled, menuOrderingUrl, orderingEventId, outOfStock, soldOut };
  const isOrderable = itemIsOrderable(orderableParams);
  const { invalidCateringLeadTime: _1, isMenuOrderingAvailable: _2, soldOut: _3, ...orderLinkableParams } = orderableParams;
  const isOrderLinkable = itemIsOrderLinkable(orderLinkableParams);

  const renderLinkOverlay = () => {
    if (includeItemLink) {
      return (
        <Button
          data-menu-item-link="true"
          aria-labelledby={ariaLabelId}
          aria-describedby={ariaDescriptionId}
          data-cy="menu_item_link"
          className={classNames('pm-next-dish-link-overlay', classes.dishLinkOverlay)}
          disableRipple={isDineInCartType}
          href={isDineInCartType ? null : menuItem.url}
          onClick={(e) => {
            if (isDineInCartType) {
              return;
            }
            openModal(e);
          }}
          variant="contained"
        >
          {' '}
        </Button>
      );
    }
    return (
      <Button
        aria-labelledby={ariaLabelId}
        aria-describedby={ariaDescriptionId}
        data-cy="menu_item_link"
        className={classNames('pm-next-dish-link-overlay', classes.dishLinkOverlay)}
        onClick={() => {
          if (isDineInCartType) {
            return;
          }
          openModal();
        }}
        variant="contained"
      >
        {' '}
      </Button>
    );
  };

  return (
    <section
      className={classNames(
        'pm-next-dish-card',
        classes.dishCard,
        menuItem.featured ? classes.dishCardFeatured : null,
      )}
    >
      {renderLinkOverlay()}
      {showPhoto && (
        <NextMenuItemCardPhoto
          dishName={menuItem.name}
          featured={menuItem.featured}
          isMobileCardWidth={isMobileCardWidth}
          menuItemMobileFullWidth={theme.menuItemMobileFullWidth ? theme.menuItemMobileFullWidth : null}
          photoUrl={menuItem.featuredPhoto.thumbnailUrl}
        />
      )}
      <div
        className={classNames(
          'pm-next-dish-card-inner',
          classes.dishCardInner,
          showPhoto ? classes.dishCardInnerWithPhoto : null,
          showPhoto && menuItem.featured ? classes.dishCardInnerFeaturedWithPhoto : null,
        )}
        id={containerId}
      >
        {menuItem.isPoppable && !modernLayout && (
          <PopBubble
            className={classNames('pm-next-pop-bubble', classes.popBubble)}
            likedItPopsCount={menuItem.likedItPopsCount}
            lovedItPopsCount={menuItem.lovedItPopsCount}
            menuItemId={menuItem.id}
            menuItemName={menuItem.name}
            poppableId={menuItem.dishId}
            poppableType="Dish"
            popsCount={menuItem.popsCount}
            wannaTryPopsCount={menuItem.wannaTryPopsCount}
          />
        )}
        {menuItem.isPoppable && modernLayout && (
          <div className={classNames('pm-next-pop-bubble', classes.popBubble)}>
            <ModernEngagementIcons menuItem={menuItem} restaurant={restaurant} compact />
          </div>
        )}
        {menuItem.featured && (
          <Typography className={classNames('pm-next-dish-featured-tag', classes.dishFeaturedTag)}>
            <FormattedMessage id="models.dish.featured" defaultMessage="Featured" />
          </Typography>
        )}
        <AH typography id={ariaLabelId} className={classNames('pm-next-dish-name', classes.dishName)} variant="h4">
          {menuItem.name}
        </AH>
        {(showDescription || showPrice || showExtras) && (
          <Typography className={classNames('pm-next-dish-description', classes.dishDescription)}>
            <Box
              id={ariaDescriptionId}
              hidden
              component="span"
            >
              {showDescription && menuItem.description}
              {showPrice && menuItem.priceCustomText ? menuItem.priceCustomText : formatCurrency(menuItem.price, currency, { showSymbol: theme.showCurrencySymbol })}
            </Box>
            {showPrice && menuItem.priceType !== 'sizes_price_type' && (
              <span className={classNames('pm-next-dish-price', classes.dishPrice)}>
                {menuItem.priceCustomText ? menuItem.priceCustomText : formatCurrency(menuItem.price, currency, { showSymbol: theme.showCurrencySymbol })}
              </span>
            )}
            {showDescription && (
              <span>
                {nl2br(menuItem.description)}
              </span>
            )}
            {showExtras && theme.showPrice && (
              <DishExtras
                classes={classes}
                component="span"
                currency={currency}
                displayExtraGroups={displayExtraGroups}
                extraGroups={menuItem.extraGroups}
                extras={menuItem.extras}
                sharedExtraGroups={menuItem.sharedExtraGroups}
                showCurrencySymbol={theme.showCurrencySymbol}
                sizes={menuItem.sizes}
              />
            )}
          </Typography>
        )}
        {menuItem.isPoppable && !modernLayout && (
          <PopBubble
            className={classNames('pm-next-pop-bubble', classes.popBubbleMobile)}
            likedItPopsCount={menuItem.likedItPopsCount}
            lovedItPopsCount={menuItem.lovedItPopsCount}
            menuItemId={menuItem.id}
            menuItemName={menuItem.name}
            poppableId={menuItem.dishId}
            poppableType="Dish"
            popsCount={menuItem.popsCount}
            wannaTryPopsCount={menuItem.wannaTryPopsCount}
          />
        )}
        {menuItem.isPoppable && modernLayout && (
          <div className={classNames('pm-next-pop-bubble', classes.popBubbleMobile)}>
            <ModernEngagementIcons menuItem={menuItem} restaurant={restaurant} compact />
          </div>
        )}
        <div className={classNames('pm-next-dish-card-bottom', classes.dishCardBottom)}>
          {!showAddToCartButton && showOrderLink && (
            <Button
              aria-label={`Order ${menuItem.name} online`}
              className={classNames('pm-next-online-ordering-button', classes.orderingButton)}
              component={outOfStock ? undefined : Link}
              disabled={!isOrderLinkable}
              onClick={() => {
                if (!isDineInCartType) {
                  createEvent({
                    eventableType: 'OnlineOrdering',
                    eventType: 'order_online_button_on_menu',
                  });
                }
                openAddToCartModal();
                dispatch(setSelectedMenuItem({
                  menuId: menuItem.menu?.id,
                  sectionId: menuItem.section?.id,
                }));
              }}
              role={outOfStock ? 'button' : 'link'}
              size="large"
              to={menuOrderingUrl}
              variant="outlined"
              data-cy="menu_item_order_online"
            >
              {outOfStock ? <FormattedMessage id="next_menu_card.out_of_stock_1" defaultMessage="Out Of Stock" /> : <FormattedMessage id="next_menu_card.order_online" defaultMessage="Order Online" />}
            </Button>
          )}
          {showAddToCartButton && !menuEmbed && (
            <Button
              aria-label={`Add ${menuItem.name} to order`}
              data-cy="add_to_order"
              className={classNames('gtm-add-to-cart-button', 'pm-next-add-to-cart-button', classes.addToCartButton)}
              startIcon={<Icon icon={CartPlus} />}
              disabled={!isOrderable}
              onClick={(e) => {
                if (!isDineInCartType) {
                  createEvent({
                    eventableType: 'OnlineOrdering',
                    eventType: 'add_to_order_menu_button',
                  });
                }
                if (modernLayout) {
                  openModal(e);
                  return;
                }
                openAddToCartModal();
              }}
              size="large"
              variant="outlined"
            >
              {soldOut ? <FormattedMessage id="next_menu_card.sold_out" defaultMessage="Sold Out" /> :
                outOfStock ? <FormattedMessage id="next_menu_card.out_of_stock_2" defaultMessage="Out of Stock" /> :
                <FormattedMessage id="next_menu_card.add_to_order" defaultMessage="Add to Order" />}
            </Button>
          )}
          {showDishTags && (
            <DishTags
              allSelectedDishTags={menuItem.dish.allSelectedDishTags}
              classes={classes}
            />
          )}
          {menuItem.reviewsCount > 0 && (
            <Button
              aria-label={`Read ${menuItem.reviewsCount} ${menuItem.reviewsCount === 1 ? 'review' : 'reviews'}`}
              className={classNames('pm-next-reviews-button', classes.reviewsButton)}
              startIcon={<Icon icon={MessageSquare} />}
              onClick={openModal}
              size="large"
              tabIndex="-1"
              variant="text"
              textTransform="none"
            >
              <FormattedMessage
                id="next_menu_card.reviews"
                defaultMessage="{reviewsCount, plural,
                  =0 {}
                  one {{reviewsCount} Review}
                  other {{reviewsCount} Reviews}
                }"
                values={{ reviewsCount: menuItem.reviewsCount }}
              />
            </Button>
          )}
          {showMenuItemPhotos && (
            <Button
              aria-label={`View ${menuItem.photosCount} ${menuItem.photosCount === 1 ? 'photo' : 'photos'}`}
              className={classNames('pm-next-photos-button', classes.photosButton)}
              startIcon={<Icon icon={Image} />}
              onClick={() => {
                if (isDineInCartType) {
                  return;
                }
                openModal();
              }}
              size="large"
              tabIndex="-1"
              variant="text"
            >
              <FormattedMessage
                id="next_menu_card.photos_count"
                defaultMessage="{photosCount}"
                values={{ photosCount: menuItem.photosCount }}
              />
            </Button>
          )}
        </div>
        {doordashDeliveryProhibited && (
          <Box marginLeft={1}>
            <Typography variant="caption">
              <FormattedMessage id="consumer.ordering.delivery_prohibited" defaultMessage="This location does not currently allow delivery of alcoholic items" />
            </Typography>
          </Box>
        )}
      </div>
    </section>
  );
};

NextMenuItemCard.defaultProps = {
  menuItemCart: null,
  menuOrderingUrl: null,
};

NextMenuItemCard.propTypes = {
  currency: PropTypes.string.isRequired,
  includeItemLink: PropTypes.bool.isRequired,
  isMenuOrderingAvailable: PropTypes.bool.isRequired,
  isMobileCardWidth: PropTypes.bool.isRequired,
  menuEmbed: PropTypes.bool.isRequired,
  menuEnabled: PropTypes.bool.isRequired,
  menuItem: PropTypes.shape({
    description: PropTypes.string,
    dishId: PropTypes.number,
    id: PropTypes.number,
    isPoppable: PropTypes.bool,
    name: PropTypes.string,
    price: PropTypes.number,
    priceCustomText: PropTypes.string,
    slug: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  menuItemCart: PropTypes.object,
  menuOrderingUrl: PropTypes.string,
  openAddToCartModal: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  showAddToCartButton: PropTypes.bool.isRequired,
  theme: themeShape.isRequired,
};

export default compose(
  withRestaurant,
  withTheme,
  withPopmenuConfig,
  mapProps(({ popmenuConfig, ...props }) => ({
    ...props,
    menuEmbed: !!popmenuConfig.widget,
  })),
)(NextMenuItemCard);
