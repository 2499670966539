import type { StyleRules } from '@material-ui/styles';
import type { Theme } from '@material-ui/core/styles';
import { MenuLayout, type MenuLayoutType } from '../../../utils/types';

type Props = {
  itemWidth: string;
  menuLayout: MenuLayoutType;
};

export default (theme: Theme) => (
  {
    defaultNavigation: {
      alignItems: 'center',
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      overflow: 'hidden',
      position: 'absolute',
      top: 0,
      zIndex: 10,
    },
    listArea: props => ({
      [theme.breakpoints.down('xs')]: {
        marginLeft: 0,
      },
      marginLeft: props.menuLayout === MenuLayout.NextLayout ? '-4px' : '10px',
      overflowX: 'hidden',
      width: '100%',
    }),
    listRow: props => ({
      '& .featured-item-dish-card': {
        marginRight: '10px',
        width: props.itemWidth,
      },
      '& .pm-loading-wrap': {
        display: 'inline-block',
        verticalAlign: 'top',
        width: props.itemWidth,
      },
      display: 'inline-block',
      transition: 'all ease 0.5s',
    }),
    navigateLeft: {
      [theme.breakpoints.down('md')]: {
        left: -35,
      },
      [theme.breakpoints.down('xs')]: {
        left: -25,
      },
      left: 0,
    },
    navigateRight: {
      [theme.breakpoints.down('md')]: {
        right: -35,
      },
      [theme.breakpoints.down('xs')]: {
        right: -25,
      },
      right: 0,
    },
  } as const satisfies StyleRules<Props>
);
