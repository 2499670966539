import React from 'react';

import JSONLD from '../shared/JSONLD';

type MenuJSONLDProps = {
  menuSchemaMarkup: string;
};

const MenuJSONLD = ({ menuSchemaMarkup }: MenuJSONLDProps) => (
  <JSONLD>
    {menuSchemaMarkup}
  </JSONLD>
);

export default MenuJSONLD;
