import React, { Fragment } from 'react';

import type { MenuDeliveryOpeningRange } from '~/libs/gql/types';
import BasicModal from '../../../admin/shared/BasicModal';
import { useIntl } from '../../../utils/withIntl';
import { makeStyles } from '../../../utils/withStyles';
import { formatDaysRollup, formatTime } from '../../../utils/time';

import menuHoursModalStyles from './styles';
import { AH, AHLevelProvider } from '../../shared/AccessibleHeading';

interface MenuHoursModalProps {
  closeModal: () => void;
  menuHours: MenuDeliveryOpeningRange[];
  show: boolean;
}

const useStyles = makeStyles(menuHoursModalStyles);

const MenuHoursModal = ({ closeModal, menuHours, show }: MenuHoursModalProps) => {
  const classes = useStyles();
  const t = useIntl();

  return (
    <BasicModal
      closeModal={closeModal}
      show={show}
      size="sm"
    >
      <Fragment>
        <AH variant="h3" className={classes.menuHoursModalTitle}>{t('models.menu/menu_hours.modal_title')}</AH>
        <AHLevelProvider>
          {menuHours.map((range, idx) => (
            <Fragment key={idx}>
              {idx > 0 && <hr className={classes.menuHoursModalDivider} />}
              <section className={classes.menuHoursModalSection}>
                <AH variant="h4" className={classes.menuHoursModalHeader}>
                  {range.days.length < 7 ? formatDaysRollup(range.days) : t('models.menu/menu_hours.modal_every_day')}
                </AH>
                {range.openAllDay ?
                  <p>{t('models.menu/menu_hours.modal_all_day')}</p> :
                  <p>{formatTime(range.openTime)} - {formatTime(range.closeTime)}</p>}
              </section>
            </Fragment>
          ))}
        </AHLevelProvider>
      </Fragment>
    </BasicModal>
  );
};

export default MenuHoursModal;
