import type { StyleRules, Theme } from '@material-ui/core/styles';

export default (theme: Theme) => (
  {
    menuHoursModalDivider: {
      borderColor: 'transparent',
      margin: `${theme.spacing(0.5)}px auto`,
    },
    menuHoursModalHeader: {
      fontWeight: 'bold',
      textAlign: 'center',
      textTransform: 'capitalize',
    },
    menuHoursModalSection: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
    menuHoursModalTitle: {
      fontWeight: 'bold',
      marginBottom: theme.spacing(4),
      textAlign: 'center',
    },
  } as const satisfies StyleRules
);
