import type { StyleRules, Theme } from '@material-ui/core/styles';

export default (theme: Theme) => ({
  containerSkeleton: {
    margin: '0 auto',
    maxWidth: '1080px',
  },
  sectionContainerSkeleton: {
    marginBottom: theme.spacing(8),
  },
  sectionNameSkeleton: {
    marginBottom: theme.spacing(4),
    marginLeft: 'auto',
    marginRight: 'auto',
  },
} as const satisfies StyleRules);
