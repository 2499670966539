import React from 'react';
import PropTypes from 'prop-types';
import { compose } from '@shakacode/recompose';

import { classNames, withStyles } from '../../../utils/withStyles';
import nextMenuItemCardPhotoStyles from './styles';

import CustomImg from '../../../shared/CustomImg';

const NextMenuItemCardPhoto = ({ classes, dishName, featured, photoUrl }) => (
  <div className={classNames('pm-next-dish-photo-wrap', classes.dishPhotoWrap)} data-cy={'menu_item_card_photo'}>
    <CustomImg
      alt={dishName}
      className={classNames('pm-next-dish-photo', classes.dishPhoto)}
      size={featured ? 'lg' : 'sm'}
      src={photoUrl}
    />
  </div>
);

NextMenuItemCardPhoto.propTypes = {
  classes: PropTypes.object.isRequired,
  dishName: PropTypes.string.isRequired,
  featured: PropTypes.bool.isRequired,
  isMobileCardWidth: PropTypes.bool.isRequired,
  menuItemMobileFullWidth: PropTypes.bool.isRequired,
  photoUrl: PropTypes.string.isRequired,
};

export default compose(
  withStyles(nextMenuItemCardPhotoStyles),
)(NextMenuItemCardPhoto);
