import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { addMinutes, isBefore, parseISO } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from '@shakacode/recompose';
import { Button, Typography } from '@popmenu/common-ui';
import { FormattedMessage } from 'react-intl';
import { useLazyQuery } from '~/lazy_apollo/client';
import { MenuLayout } from '~/utils/types';
import { createEvent, createPageView } from '~/utils/eventable';
import menuOrderingAvailabilityQuery from '../../../libs/gql/queries/menus/menuAvailabilityQuery.gql';

import { sortByKey } from '../../../utils/arrays';
import { readableFontColor } from '../../../utils/colors';
import { nl2br } from '../../../utils/react';
import { scrollTo } from '../../../utils/dom';
import { useCurrentSession } from '../../../shared/CurrentSessionProvider';
import { classNames, makeStyles } from '../../../utils/withStyles';
import { themeShape, withTheme } from '../../../utils/withTheme';
import { openReviewSectionModal } from '../../../shared/ModalActions';
import { withRestaurant } from '../../../utils/withRestaurant';
import { useWindowSizeContext } from '../../../shared/WindowSizeProvider';
import { findElement } from '../../../utils/dom';
import {
  setSelectedSectionIndex,
  setSelectedSectionOffset,
  setSelectedSectionSlug,
} from '../../../shared/MenuItemCartActions';

import DishTagsBar from '../DishTagsBar';
import FollowBanner from '../FollowBanner';
import Grid from '../../../shared/Grid';
import MenuHoursBanner from '../MenuHoursBanner/MenuHoursBanner';
import MenuFeaturedSectionQuery from '../MenuFeaturedSectionQuery/MenuFeaturedSectionQuery';
import MenuJSONLD from '../MenuJSONLD';
import MenuSection from '../MenuSection';

import ReservationWidget from '../../shared/ReservationWidget';
import ShareButton from '../../shared/ShareButton';

import { getMenuHours, isMenuEnabled, shouldUseMenuHours } from '../MenuHelpers';
import MultiSectionLazyContainer from '../../../shared/MultiSectionLazyContainer';
import { AH, AHLevelProvider } from '../../shared/AccessibleHeading';

import VirtualizedMenuSection, { fetchMenuSection } from './VirtuosoMenu/VirtualizedMenuSection';
import { VirtualizedMenuSections } from './VirtuosoMenu/VirtualizedMenuSections';
import { VirtualizedMenuContext } from './VirtuosoMenu/VirtualizedMenuContext';

const styles = t => ({
  cateringLeadTimeHeading: {
    fontSize: '1.125em',
  },
  cateringLeadTimeSubheading: {
    fontSize: '0.875em',
  },
  cateringLeadTimeSummaryHeaders: {
    backgroundColor: t.consumer.menuItemCart.summary.header.backgroundColor,
    marginLeft: 0,
    marginRight: 0,
    paddingBottom: t.spacing(2),
    paddingLeft: t.spacing(2),
    paddingRight: t.spacing(2),
    paddingTop: t.spacing(2),
    textAlign: 'center',
  },
});

const useStyles = makeStyles(styles);

const NUMBER_OF_PRERENDERED_SECTIONS = 2;

const Menu = ({
  allowReservation,
  backgroundColor,
  cateringLeadTimeMinutes,
  currency,
  disclaimer,
  dishTags,
  displayExtraGroups,
  displayPdfType,
  enabledFeaturedSection,
  enabledSections,
  id,
  includeItemLink,
  isCateringMenu,
  isMenuHours,
  isMenuHoursDelivery,
  itemBackgroundColor,
  location,
  locationId,
  menuColumnCount,
  menuDeliveryOpeningRanges,
  menuItemCart,
  menuLayout,
  menuOrderingOpeningRanges,
  menuSchemaMarkup,
  name,
  pdfLinkUrl,
  popularMenuItems,
  restaurant,
  selectedMenuSectionId,
  selectedPrintLayout,
  setSelectedTab,
  shortLinkUrl,
  showAddToCartButton,
  slug,
  theme,
  trackPageView,
  menuOrderingUrl,
  ...props
}) => {
  const { setFeaturedSectionEnabled } = useContext(VirtualizedMenuContext);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { isMobile } = useWindowSizeContext();
  const currentSession = useCurrentSession();
  const hasReview = currentSession.user ? currentSession.user.reviews.filter(review => review.restaurant.id === restaurant.id).length > 0 : false;
  const isScheduled = useSelector(state => state.menuItemCart.menuItemCartIsScheduled);
  const scheduledAt = useSelector(state => state.menuItemCart.menuItemCartScheduledAt);
  const selectedSectionIndex = useSelector(state => state.menuItemCart.selectedSectionIndex);
  const selectedSectionSlug = useSelector(state => state.menuItemCart.selectedSectionSlug);
  const shouldPreloadSections = useSelector(state => state.menuItemCart.shouldPreloadSections);
  const selectedSectionOffset = useSelector(state => state.menuItemCart.selectedSectionOffset);
  const shouldShowCateringLeadTimeWarning = isCateringMenu &&
    cateringLeadTimeMinutes > 0 &&
    isScheduled && isBefore(parseISO(scheduledAt), addMinutes(new Date(), cateringLeadTimeMinutes));
  // -1 means users did not scroll to any sections yet.
  const [scrolledSectionIndex, setScrolledSectionIndex] = useState(-1);
  const [selectedDishTags, setSelectedDishTags] = useState([]);
  // This state keeps track of sections with their content loaded.
  const [loadedSectionIndex, setLoadedSectionIndex] = useState(0);
  const getAvailabilityVars = () => {
    const vars = { menuId: id };
    if (menuItemCart) {
      vars.cartType = menuItemCart.cartType;
      vars.fulfillmentType = menuItemCart.fulfillmentType;
      vars.scheduledAt = menuItemCart.scheduledAt;
    }
    return vars;
  };
  const [fetchMenuOrderingAvailability] = useLazyQuery(menuOrderingAvailabilityQuery, { fetchPolicy: 'network-only', variables: getAvailabilityVars() });
  const [isOrderingAvailable, setIsOrderingAvailable] = useState(props.isOrderingAvailable);

  const namedMenuLayout = menuLayout || theme.defaultMenuLayout || MenuLayout.NextLayout;
  const sectionsToConsider = showAddToCartButton ? enabledSections.filter(({ isOrderingSection }) => isOrderingSection) : enabledSections;

  const sectionsToRender = sectionsToConsider
    .filter(section => !selectedMenuSectionId || section.id === selectedMenuSectionId)
    .map(section => ({
      ...section,
      fetchData: fetchMenuSection, // fetchData is used by VirtualizedMenuSections
    }));

  const featuredSectionSlug = 'featuredslug';
  const menuEnabled = isMenuEnabled({ isMenuHours, isMenuHoursDelivery, isOrderingAvailable, menuItemCart });

  // occurs in a useEffect to prevent SSR to bypass any caching
  // This ensures the values is always correct, but often the cached value will be correct
  // so no changes will come from this call
  useEffect(() => {
    fetchMenuOrderingAvailability().then(({ data }) => {
      if (data?.menu) {
        // follows the same fallback logic as the MenuContainer to ensure matching outcomes
        setIsOrderingAvailable(data.menu.isOrderingAvailable);
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // Attach to snowplow page tracking
    if (trackPageView) {
      createPageView([{
        data: {
          id,
        },
        schema: 'iglu:com.popmenu/menu/jsonschema/1-0-0',
      }]);
    }
  }, [trackPageView, id]);

  const handleSectionScroll = () => {
    // Once sections have loaded, attempt to find the section
    const sectionEl = findElement(`#section-${selectedSectionSlug}`);
    let sectionIndex = 0;

    if (selectedSectionSlug !== featuredSectionSlug) {
      sectionIndex = sectionsToRender.findIndex(section => section.slug === selectedSectionSlug);
    }

    if (sectionEl && sectionIndex <= loadedSectionIndex + 1) {
      // If the selected section's index is greater than the loaded section's index, it means that the section hasn't loaded yet and should not scroll
      // If section is found, scrollTo with offset, default of 0 for web
      scrollTo(sectionEl, selectedSectionOffset);
      // After scrolling to the selected section, reset redux state to null
      dispatch(setSelectedSectionOffset(0));
      dispatch(setSelectedSectionSlug(null));
      dispatch(setSelectedSectionIndex(-1));
    }
  };

  const renderGenericSection = ({ customNode = null, description = null, id: sectionId = null, items = [], name: sectionName = null }) => {
    let subsectionType = null;
    if (customNode) {
      subsectionType = 'custom';
    } else if (items.length) {
      subsectionType = 'default';
    } else {
      return null;
    }
    const section = {
      description,
      name: sectionName,
      subsections: [{
        customNode,
        id: 0,
        isEnabled: true,
        items,
        subsectionType,
      }],
    };
    return (
      <MenuSection
        currency={currency}
        displayExtraGroups={displayExtraGroups}
        elementId={sectionId}
        includeItemLink={includeItemLink}
        itemBackgroundColor={itemBackgroundColor}
        masterMenuColumnCount={menuColumnCount}
        menuBackgroundColor={backgroundColor}
        menuEnabled={menuEnabled}
        menuItemCart={menuItemCart}
        menuLayout={namedMenuLayout}
        menuOrderingUrl={menuOrderingUrl}
        isMenuOrderingAvailable={isOrderingAvailable}
        menuSlug={slug}
        locationId={locationId}
        {...section}
        selectedDishTags={selectedDishTags}
      />
    );
  };

  const renderPopularSection = (menuItems) => {
    if (showAddToCartButton || !theme.showPopularSection || menuItems.length <= 3) {
      return null;
    }
    const popularItems = sortByKey(menuItems.slice(), 'visitsCount').reverse().slice(0, 3).map(item => ({
      ...item,
      featured: true,
      isPopular: true,
    }));
    return renderGenericSection({
      description: 'Crowd favorites that are sure to impress.',
      id: 'popular',
      items: popularItems,
      name: 'Most Popular',
    });
  };

  const renderReservationSection = () => {
    const allowReservations = allowReservation && location && location.allowReservations && (location.openTableId || location.resyVenueId || location.yelpFriendlyId);
    if (showAddToCartButton || !(allowReservations)) {
      return null;
    }
    const customNode = (
      <ReservationWidget
        eventableId={id}
        eventableType="Menu"
        location={location}
        widgetId={`menu-${id}`}
      />
    );
    return renderGenericSection({
      customNode,
      id: 'menu_reservations',
      name: 'Reservations',
    });
  };

  const renderDisclaimer = () => {
    if (disclaimer) {
      const style = {};
      if (theme.menuHeaderFont.color) {
        style.color = theme.menuHeaderFont.color;
      }
      return (
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <p className="menu-disclaimer" style={style}>
              {nl2br(disclaimer)}
            </p>
          </Grid>
        </Grid>
      );
    }
    return null;
  };

  const handleDishTags = (newDishTags) => {
    setSelectedDishTags(newDishTags, () => console.log(selectedDishTags));
  };

  const renderMenuDownload = () => {
    if (displayPdfType === 'pdf_print_center' || displayPdfType === 'pdf_link') {
      const downloadLink = pdfLinkUrl || (selectedPrintLayout ? selectedPrintLayout.printLayoutUrl : null);
      if (downloadLink) {
        return (
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <div className="menu-download">
                <Button
                  href={downloadLink}
                  type="button"
                  onClick={() => {
                    createEvent({
                      eventableId: id,
                      eventableType: 'Menu',
                      eventType: 'pdf_menu_download_event',
                      restaurantId: restaurant.id,
                    });
                  }}
                  style={{
                    backgroundColor: theme.menuHeaderFont.color,
                    color: '#fff',
                  }}
                  target="_blank"
                  variant="contained"
                ><FormattedMessage id="menus.download_menu" defaultMessage="Download Menu" />
                </Button>
              </div>
            </Grid>
          </Grid>
        );
      }
    }
    return null;
  };

  const renderMenuReviewSection = () => {
    if (showAddToCartButton || !theme.showMenuReviewSection || hasReview) {
      return null;
    }
    const customNode = (
      <Grid container spacing={0}>
        <Grid item xs={12} sm={8}>
          <div className="pm-menu-share-section">
            <AH variant="h3"><FormattedMessage id="menus.like_any_items_ordered" defaultMessage="Like any of the items you ordered?" /></AH>
            <p><FormattedMessage id="menus.feedback" defaultMessage="Thanks for visiting! We&apos;d love to have your feedback." /></p>
          </div>
        </Grid>
        <Grid item xs={12} sm={4}>
          <div
            className="pm-menu-share-section pm-menu-share"
            style={{ backgroundColor: theme.primaryColor }}
          >
            <button
              className="btn"
              onClick={() => dispatch(openReviewSectionModal())}
              type="button"
            >
              <FormattedMessage id="menus.add_your_review" defaultMessage="Add Your Review" />
            </button>
          </div>
        </Grid>
      </Grid>
    );
    return renderGenericSection({ customNode });
  };

  const renderMenuShareSection = () => {
    if (showAddToCartButton || !theme.showMenuShareSection || !shortLinkUrl) {
      return null;
    }
    const quote = `Check out the ${name} menu at ${restaurant.name}: ${shortLinkUrl}`;
    const customNode = (
      <Grid container spacing={0}>
        <Grid item xs={12} sm={8}>
          <div className="pm-menu-share-section">
            <AH variant="h3"><FormattedMessage id="menus.send_to_a_friend" defaultMessage="Send to a friend" /></AH>
            <p><FormattedMessage id="menus.send_to_a_friend" defaultMessage="Share this menu with your friends ahead of time to get their mouths watering." /></p>
          </div>
        </Grid>
        <AHLevelProvider>
          <Grid item xs={12} sm={4}>
            <div
              className="pm-menu-share-section pm-menu-share"
              style={{ backgroundColor: theme.primaryColor }}
            >
              <ShareButton
                eventableId={id}
                eventableType="Menu"
                photoUrl={restaurant.photoUrl}
                quote={quote}
                style={{ color: readableFontColor(theme.primaryColor) }}
                url={shortLinkUrl}
                variant="text"
              >
                <FormattedMessage id="menus.share_this_menu" defaultMessage="Share this menu" />
              </ShareButton>
            </div>
          </Grid>
        </AHLevelProvider>
      </Grid>
    );
    return renderGenericSection({ customNode });
  };

  const setNewScrolledSectionIndex = (newScrolledSectionIndex) => {
    if (newScrolledSectionIndex >= 1 && scrolledSectionIndex < newScrolledSectionIndex) {
      setScrolledSectionIndex(newScrolledSectionIndex);
    }
  };

  const shouldPrerenderSection = (sectionIndex) => {
    // Some pages have a small header or no header so these pages will result in instantaneous rendering of first section.
    // And, this will result in instantaneous prerendering of second and third section, which will result in LH regressions.
    // So, to improve Lighthouse scores, we start prerendering sections only when users scroll to the second section.

    // Instead of using redux state to change local state, we are utilizing both to determine which to calculate with. The higher of the two (manual scroll index or selected section index) will be used.
    if (scrolledSectionIndex < 1 && selectedSectionIndex < 1) {
      return false;
    }
    return sectionIndex - (scrolledSectionIndex > selectedSectionIndex ? scrolledSectionIndex : selectedSectionIndex) <= NUMBER_OF_PRERENDERED_SECTIONS;
  };

  const isUsingMenuHours = shouldUseMenuHours({
    fulfillmentType: menuItemCart?.fulfillmentType,
    isMenuHours,
    isMenuHoursDelivery,
  });

  setFeaturedSectionEnabled(enabledFeaturedSection); // No need in useEffect. The call doesn't cause re-rendering.
  const setSelectedTabAdjustedToFeaturedSection = useCallback((index) => {
    setSelectedTab?.(enabledFeaturedSection ? index + 1 : index);
  }, [enabledFeaturedSection, setSelectedTab]);

  const renderVirtualizedMenuSection = (index, section) => (
    <Fragment>
      <VirtualizedMenuSection
        index={index}
        currency={currency}
        displayExtraGroups={displayExtraGroups}
        elementId={`section-${section.slug}`}
        includeItemLink={includeItemLink}
        isMenuOrderingAvailable={isOrderingAvailable}
        itemBackgroundColor={itemBackgroundColor}
        masterMenuColumnCount={menuColumnCount}
        menuBackgroundColor={backgroundColor}
        menuEnabled={menuEnabled}
        menuItemCart={menuItemCart}
        menuOrderingUrl={menuOrderingUrl}
        menuSlug={slug}
        {...section}
        menuLayout={
          section.menuLayout || namedMenuLayout
        }
        renderMobileMenuNavV2={
          isMobile &&
          menuItemCart?.cartType !== 'dine_in_cart_type'
        }
        selectedDishTags={selectedDishTags}
        setSelectedTab={setSelectedTabAdjustedToFeaturedSection}
        showAddToCartButton={showAddToCartButton}
        locationId={locationId}
      />
      {index === 0 && renderMenuShareSection()}
    </Fragment>
  );

  return (
    <React.Fragment>
      {shouldShowCateringLeadTimeWarning && (
        <div className={classNames('pm-cart-summary-header', classes.cateringLeadTimeSummaryHeaders)}>
          <Typography className={classes.cateringLeadTimeHeading}>
            <FormattedMessage
              id="consumer.ordering.catering.lead_time_heading"
              defaultMessage="All items on this menu have a {leadTime} hour lead time"
              values={{ leadTime: Math.round(cateringLeadTimeMinutes * 10 / 60) / 10 }}
            />
          </Typography>
          <Typography className={classes.cateringLeadTimeSubheading}>
            <FormattedMessage
              id="consumer.ordering.catering.lead_time_subheading"
              defaultMessage="To add items from this menu, change the date and time of this order"
            />
          </Typography>
        </div>
      )}
      <div
        className="pm-menu-wrap"
        id={`pm-menu-${slug}`}
      >
        <div id={`menu-${slug}`}>
          {!showAddToCartButton && theme.showFollowSection && theme.menuTabsLayout !== 'card_menu_tabs_layout' && (
            <FollowBanner />
          )}
          {isUsingMenuHours && !menuEnabled && (
            <MenuHoursBanner
              menuHours={getMenuHours({
                deliveryHours: menuDeliveryOpeningRanges,
                fulfillmentType: menuItemCart?.fulfillmentType,
                pickupHours: menuOrderingOpeningRanges,
              })}
            />
          )}
          {theme.dishTagKeyPosition === 'dtk_top' && (
            <DishTagsBar dishTags={dishTags} selectedState={selectedDishTags} handleDishTags={handleDishTags} />
          )}
          <AHLevelProvider>
            <MultiSectionLazyContainer
              firstHiddenSection={shouldPreloadSections ? 0 : 1}
            >
              {renderPopularSection(popularMenuItems)}
              {renderMenuReviewSection()}
              {enabledFeaturedSection && (
                <React.Fragment>
                  <MenuFeaturedSectionQuery
                    currency={currency}
                    displayExtraGroups={displayExtraGroups}
                    elementId={`section-${id}`}
                    isCateringMenu={isCateringMenu}
                    isMenuOrderingAvailable={isOrderingAvailable}
                    includeItemLink={includeItemLink}
                    itemBackgroundColor={itemBackgroundColor}
                    masterMenuColumnCount={menuColumnCount}
                    menuBackgroundColor={backgroundColor}
                    menuEnabled={menuEnabled}
                    menuId={id}
                    menuItemCart={menuItemCart}
                    menuOrderingUrl={menuOrderingUrl}
                    menuSlug={slug}
                    menuLayout={namedMenuLayout}
                    sectionIndex={0}
                    setSelectedTab={setSelectedTab}
                    renderMobileMenuNavV2={isMobile && menuItemCart?.cartType !== 'dine_in_cart_type'}
                    selectedDishTags={selectedDishTags}
                    showAddToCartButton={showAddToCartButton}
                    slug={featuredSectionSlug}
                    locationId={locationId}
                    handleSectionScroll={handleSectionScroll}
                    onScrolled={setNewScrolledSectionIndex}
                    shouldPrerenderSection={shouldPrerenderSection}
                    loadedSectionIndex={loadedSectionIndex}
                    setLoadedSectionIndex={setLoadedSectionIndex}
                  />
                </React.Fragment>
              )}
              <React.Fragment>
                <VirtualizedMenuSections
                  sections={sectionsToRender}
                  itemContent={renderVirtualizedMenuSection}
                />
              </React.Fragment>
              {renderReservationSection()}
              {theme.dishTagKeyPosition === 'dtk_bottom' && (
                <DishTagsBar dishTags={dishTags} selectedState={selectedDishTags} handleDishTags={handleDishTags} />
              )}
              {renderDisclaimer()}
              {renderMenuDownload()}
            </MultiSectionLazyContainer>
          </AHLevelProvider>
        </div>
      </div>
      <MenuJSONLD menuSchemaMarkup={menuSchemaMarkup} />
    </React.Fragment>
  );
};

Menu.defaultProps = {
  allowReservation: true,
  backgroundColor: null,
  backgroundEffect: null,
  backgroundImageUrl: null,
  disclaimer: null,
  displayExtraGroups: false,
  displayPdfType: null,
  enabledSections: [],
  includeItemLink: true,
  itemBackgroundColor: null,
  menuItemCart: null,
  menuLayout: null,
  menuOrderingUrl: null,
  menuSchemaMarkup: null,
  pdfLinkUrl: null,
  selectedMenuSectionId: null,
  selectedPrintLayout: null,
  showAddToCartButton: false,
  trackPageView: false,
};

Menu.propTypes = {
  allowReservation: PropTypes.bool,
  backgroundColor: PropTypes.string,
  backgroundEffect: PropTypes.string,
  backgroundImageUrl: PropTypes.string,
  currency: PropTypes.string.isRequired,
  disclaimer: PropTypes.string,
  displayExtraGroups: PropTypes.bool,
  displayPdfType: PropTypes.string,
  enabledSections: PropTypes.arrayOf(PropTypes.shape({
    isEnabled: PropTypes.bool,
    isFullWidth: PropTypes.bool,
    isOrderingSection: PropTypes.bool,
    name: PropTypes.string,
    subsections: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      items: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        price: PropTypes.number,
      })),
    })),
  })),
  id: PropTypes.number.isRequired,
  includeItemLink: PropTypes.bool,
  itemBackgroundColor: PropTypes.string,
  locationId: PropTypes.number.isRequired,
  menuItemCart: PropTypes.object,
  menuLayout: PropTypes.string,
  menuOrderingUrl: PropTypes.string,
  menuSchemaMarkup: PropTypes.string,
  name: PropTypes.string.isRequired,
  pdfLinkUrl: PropTypes.string,
  restaurant: PropTypes.shape({
    id: PropTypes.number,
    locations: PropTypes.arrayOf(PropTypes.shape({
      allowReservations: PropTypes.bool,
      id: PropTypes.number,
      openTableId: PropTypes.string,
      resyVenueId: PropTypes.number,
      yelpFriendlyId: PropTypes.string,
    })),
    logoUrl: PropTypes.string,
    name: PropTypes.string,
    photoUrl: PropTypes.string,
    signUpHeadingCta: PropTypes.string,
    signUpSubheadingCta: PropTypes.string,
  }).isRequired,
  selectedMenuSectionId: PropTypes.number,
  selectedPrintLayout: PropTypes.shape({
    id: PropTypes.number,
    printLayoutUrl: PropTypes.string,
  }),
  shortLinkUrl: PropTypes.string.isRequired,
  showAddToCartButton: PropTypes.bool,
  slug: PropTypes.string.isRequired,
  theme: themeShape.isRequired,
  trackPageView: PropTypes.bool,
};

export default compose(
  withTheme,
  withRestaurant,
)(Menu);
