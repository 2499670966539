import type { StyleRules, Theme } from '@material-ui/core/styles';

export default (theme: Theme) => ({
  stickyButton: {
    background: 'none',
    border: `1px solid ${theme.consumer.followPrompt.color}`,
    borderRadius: theme.spacing(2),
    color: theme.consumer.followPrompt.color,
    float: 'right',
    fontWeight: 'bold',
    margin: theme.spacing(0, 0, 0, 1),
    padding: theme.spacing(0.5, 2),
    textTransform: 'uppercase',
  },
  stickyContainer: {
    position: 'static',
    top: 0,
    transition: 'top ease-in 300ms',
  },
  stickyHeading: {
    color: theme.consumer.followPrompt.color,
    float: 'left',
    fontWeight: 'bold',
    lineHeight: `${theme.spacing(4)}px`,
    marginRight: theme.spacing(1),
    textTransform: 'uppercase',
  },
  stickyInner: {
    backgroundColor: theme.consumer.followPrompt.backgroundColor,
  },
  stickyInnerContainer: {
    background: 'none',
    margin: '0 auto',
    maxWidth: '1200px',
    overflow: 'hidden',
    padding: theme.spacing(2, 4),
    width: 'auto',
  },
  stickySubheading: {
    color: theme.consumer.followPrompt.color,
    float: 'left',
    lineHeight: `${theme.spacing(4)}px`,
  },
  stickyWrap: {
    cursor: 'pointer',
    marginBottom: theme.spacing(2),
  },
} as const satisfies StyleRules);
