import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

import { useTheme } from '../../../utils/withTheme';
import { makeStyles } from '../../../utils/withStyles';
import { toGridSizeOrUndefined } from '../../../utils/styles';
import menuSectionSkeletonStyles from './styles';

import Grid from '../../../shared/Grid';

const useStyles = makeStyles(menuSectionSkeletonStyles);

type MenuSectionSkeletonProps = {
  enabledItemsCount?: number;
  menuColumnCount?: number;
}

const MenuSectionSkeleton = ({ enabledItemsCount = 4, menuColumnCount = undefined }: MenuSectionSkeletonProps) => {
  const theme = useTheme();
  // Use the provided `menuColumnCount` prop, or use the theme default
  const usedMenuColumnCount = (typeof menuColumnCount === 'number' ? menuColumnCount : theme.menuColumnCount) ?? 1; // ensure non-null;

  const classes = useStyles();
  return (
    <div className={classes.sectionContainerSkeleton}>
      <Skeleton className={classes.sectionNameSkeleton} width="30%" />
      {Boolean(enabledItemsCount) && enabledItemsCount > 0 && (
        <Grid container>
          {[...Array(enabledItemsCount).keys()].map(index => (
            <Grid key={index} item md={toGridSizeOrUndefined(12 / usedMenuColumnCount)} xs={12}>
              <Skeleton width="30%" />
              <Skeleton width="100%" />
              <Skeleton width="100%" />
            </Grid>
          ))}
        </Grid>
      )}
    </div>
  );
};

export default MenuSectionSkeleton;
