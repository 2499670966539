import React, { memo } from 'react';

import { useDispatch } from '~/utils/redux';
import { useCurrentSession } from '../../../shared/CurrentSessionProvider';
import { useRestaurant } from '../../../utils/withRestaurant';
import { openVipV2Modal } from '../../../shared/ModalActions';
import { classNames, makeStyles } from '../../../utils/withStyles';
import followBannerStyles from './styles';

import CustomLink from '../../shared/CustomLink';

const useStyles = makeStyles(followBannerStyles);

const FollowBanner = () => {
  const isUser = !!useCurrentSession().user;
  const {
    customFollowPath,
    followButtonCta,
    followHeadingCta,
    followSubheadingCta,
  } = useRestaurant() ?? {};
  const classes = useStyles();
  const dispatch = useDispatch();

  const renderLink = () => {
    if (customFollowPath) {
      return (
        <CustomLink
          className={classes.stickyButton}
          url={customFollowPath}
        >
          {followButtonCta}
        </CustomLink>
      );
    }
    return (
      <button
        onClick={() => dispatch(openVipV2Modal())}
        className={classes.stickyButton}
        type="button"
      >
        {followButtonCta}
      </button>
    );
  };

  /* eslint-disable jsx-a11y/click-events-have-key-events */
  /* eslint-disable jsx-a11y/no-static-element-interactions */
  if (isUser) {
    return null;
  }
  return (
    <div
      className={classes.stickyWrap}
      onClick={() => {
        if (!customFollowPath) {
          dispatch(openVipV2Modal());
        }
      }}
    >
      <div
        className={classNames(
          'pm-follow-wrap',
          classes.stickyContainer,
        )}
      >
        <div className={classes.stickyInner}>
          <div className={classes.stickyInnerContainer}>
            <span className={classes.stickyHeading}>{followHeadingCta}</span>
            <span className={classes.stickySubheading}>{followSubheadingCta}</span>
            {renderLink()}
          </div>
        </div>
      </div>
    </div>
  );
  /* eslint-enable jsx-a11y/no-static-element-interactions */
  /* eslint-enable jsx-a11y/click-events-have-key-events */
};

export default memo(FollowBanner);
