import React, { useEffect, useState, useMemo, useRef } from 'react';
import { IconButton, Icon } from '@popmenu/common-ui';
import { ChevronLeft, ChevronRight } from '@popmenu/web-icons';
import { useSwipeable } from 'react-swipeable';
import { useWindowSizeContext } from '../../../shared/WindowSizeProvider';
import { classNames, makeStyles } from '../../../utils/withStyles';
import { MenuLayout, type MenuLayoutType } from '../../../utils/types';
import styles from './styles';
import MultiSectionLazyContainer from '../../../shared/MultiSectionLazyContainer';

const useStyles = makeStyles(styles);

const visibilityOptions = {
  threshold: 0.50,
};
const lazyScrollObserverOptions = {
  earlyVisibilityOptions: visibilityOptions,
  lateVisibilityOptions: visibilityOptions,
};

type FeaturedSectionLayoutProps = {
  children: React.JSX.Element | React.JSX.Element[];
  layout: string;
  length: number;
  menuLayout: MenuLayoutType;
  showAddToCartButton: boolean;
};

const displayCarouselCardsObserver = (rootElement: HTMLDivElement | null) => new IntersectionObserver((entries) => {
  entries.forEach((entry: IntersectionObserverEntry) => {
    const visiblityValue = entry.isIntersecting ? 'visible' : 'hidden';
    const el: HTMLElement = entry.target as HTMLElement;
    el.style.visibility = visiblityValue;
  });
}, { root: rootElement, threshold: 0.2 });

const FeaturedSectionLayout = ({
  children,
  layout,
  menuLayout,
  length,
  showAddToCartButton,
}: FeaturedSectionLayoutProps) => {
  const { isMobile } = useWindowSizeContext();
  const windowAttrs = useMemo(() => (
    {
      paddingCard: ((isMobile || menuLayout !== MenuLayout.NextLayout) ? 0 : 8),
      windowColumnNavigate: (isMobile || showAddToCartButton ? 2 : 3),
    }
  ), [isMobile, menuLayout, showAddToCartButton]);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const [scrollX, setScrollX] = useState(0);
  const [cardWidth, setCardWidth] = useState(512);
  const [itemWidth, setItemWidth] = useState('512px');
  const classes = useStyles({ itemWidth, menuLayout });

  const widthListRow = (length + 1) * cardWidth;
  const limitRightNavigation = (cardWidth * windowAttrs.windowColumnNavigate) - widthListRow;
  const shouldShowNavigationArrow = ((isMobile || showAddToCartButton) && length > 1 || length > 2);

  const leftNavigation = () => {
    let x = scrollX + (cardWidth);
    if (x > 0) {
      x = 0;
    }
    setScrollX(x);
  };

  const rightNavigation = () => {
    let x = scrollX - (cardWidth);
    if (x < limitRightNavigation) {
      x = limitRightNavigation;
    }
    setScrollX(x);
  };

  const swipeableProps = useSwipeable({
    onSwipedLeft: () => leftNavigation(),
    onSwipedRight: () => rightNavigation(),
    preventDefaultTouchmoveEvent: true,
  });

  const getContainerSize = (containerElement: Element) => (
    +(getComputedStyle(containerElement).width.replace('px', ''))
  );

  useEffect(() => {
    const resizeItemsCard = () => {
      const containerElement = containerRef.current;
      if (!containerElement) return;

      if (layout === 'carousel') {
        const visibleSlides = (isMobile || showAddToCartButton ? 1 : 2);
        const listAreaWidth = getContainerSize(containerElement);
        const currentCardWidth = listAreaWidth / visibleSlides;
        const itemWidthTemp = `${(currentCardWidth) - windowAttrs.paddingCard}px`;
        setItemWidth(`calc(${itemWidthTemp} - 10px)`);
        setCardWidth(currentCardWidth);
      }
    };
    window.addEventListener('resize', resizeItemsCard);
    resizeItemsCard();

    return () => {
      window.removeEventListener('resize', resizeItemsCard);
    };
  }, [isMobile, layout, showAddToCartButton, menuLayout, windowAttrs]);

  // on scroll, change visibility to 'visible' right as the card enters the visible area
  // this is for ADA compliance to ensure the tab order is correct
  useEffect(() => {
    const cardSelector = '[id^="featured-item-card-"]';
    const observer = displayCarouselCardsObserver(containerRef.current);
    document.querySelectorAll(cardSelector).forEach(el => observer.observe(el));
    return () => observer.disconnect();
  }, [scrollX]);

  return (
    <React.Fragment>
      {layout === 'list' && (
        children
      )}
      {layout === 'carousel' && (
      <React.Fragment>
        { shouldShowNavigationArrow && (
          <React.Fragment>
            <div className={classNames([classes.navigateLeft, classes.defaultNavigation])}>
              <IconButton
                style={{ fontSize: 40 }}
                data-tour-id="featured-carousel-left-navigation"
                disabled={scrollX >= 0}
                disableFocusRipple
                onClick={() => leftNavigation()}
                color="secondary"
                aria-label="Previous"
              >
                <Icon icon={ChevronLeft} />
              </IconButton>
            </div>
            <div className={classNames([classes.navigateRight, classes.defaultNavigation])}>
              <IconButton
                style={{ fontSize: 40 }}
                data-tour-id="featured-carousel-right-navigation"
                disabled={scrollX <= limitRightNavigation}
                disableFocusRipple
                onClick={() => rightNavigation()}
                color="secondary"
                aria-label="Next"
              >
                <Icon icon={ChevronRight} />
              </IconButton>
            </div>
          </React.Fragment>
        )}
        <div ref={containerRef} className={classes.listArea}>
          <div className={classes.listRow} style={{ marginLeft: scrollX, width: `${widthListRow}px` }} {...swipeableProps}>
            <MultiSectionLazyContainer
              firstHiddenSection={1}
              loaderVariant="circular"
              lazyScrollObserverOptions={lazyScrollObserverOptions}
            >
              {children}
            </MultiSectionLazyContainer>
          </div>
        </div>
      </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default FeaturedSectionLayout;
