import React from 'react';
import { Icon } from '@popmenu/common-ui';
import { Calendar, Heart, ThumbsUp } from '@popmenu/web-icons';
import PropTypes from 'prop-types';
import { toCamelCase } from '../../utils/utils';
import { isDarkColor } from '../../utils/colors';
import { withIntl } from '../../utils/withIntl';

const POP_TYPE_ICONS = {
  'calendar-o': Calendar,
  heart: Heart,
  'thumbs-up': ThumbsUp,
};

class PopsCombo extends React.PureComponent {
  render() {
    const { userPop, userReview } = this.props;
    let popIcons = null;
    let popCount = null;
    if (this.props.isPoppable) {
      if (this.props.popsCount > 0) {
        popIcons = ['loved_it', 'liked_it', 'wanna_try'].map((popType) => {
          const count = this.props[`${toCamelCase(popType)}PopsCount`];
          if (count === 0) {
            return null;
          }
          const popped = userPop && userPop.popType === popType;
          return (
            <span className={`pm-pops-bubble pm-pops-${popType.replace('_', '-')}${popped ? ' popped' : ''}`} key={popType}>
              <Icon icon={POP_TYPE_ICONS[this.props.t(`pops.pop_types.${popType}.icon`)]} />
              <span className="pm-pops-bubble-count">{count}</span>
            </span>
          );
        });
        popCount = (
          <span className="pm-pops-count">
            {this.props.popsCount === 1 ? '1 pop' : `${this.props.popsCount} pops`}
          </span>
        );
      }
    }

    let reviewCount = null;
    const reviewsCount = this.props.reviewsCount || (userReview ? 1 : 0);
    if (reviewsCount > 0) {
      reviewCount = (
        <span className="pm-reviews-count">
          {reviewsCount === 1 ? '1 review' : `${reviewsCount} reviews`}
        </span>
      );
    }

    if (popIcons || popCount || reviewCount) {
      return (
        /* eslint-disable jsx-a11y/click-events-have-key-events */
        /* eslint-disable jsx-a11y/no-static-element-interactions */
        <div
          className={`pm-pops-combo ${isDarkColor(this.props.backgroundColor || '#FFF') ? 'light' : 'dark'}`}
          onClick={this.props.onClick}
        >
          <div className="pm-pops-inner">
            {popIcons}
            {popCount}
            {reviewCount}
          </div>
        </div>
        /* eslint-enable jsx-a11y/no-static-element-interactions */
        /* eslint-enable jsx-a11y/click-events-have-key-events */
      );
    }
    return null;
  }
}

PopsCombo.defaultProps = {
  backgroundColor: null,
  onClick: null,
  pops: [],
  userPop: null,
  userReview: null,
};

PopsCombo.propTypes = {
  backgroundColor: PropTypes.string,
  dishId: PropTypes.number.isRequired,
  isPoppable: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  pops: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    popType: PropTypes.string,
    user: PropTypes.shape({
      displayName: PropTypes.string,
      thumbnailUrl: PropTypes.string,
    }),
  })),
  popsCount: PropTypes.number.isRequired,
  reviewsCount: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  userPop: PropTypes.shape({
    popType: PropTypes.string,
  }),
  userReview: PropTypes.shape({
    content: PropTypes.string,
  }),
};

export default withIntl(PopsCombo);
